import $ from 'jquery';

export class PrimaryNav {
  constructor($el) {
    this.$el = $el;
    this.$navTabs = $el.find('.js-navigation__tab');
    this.$mobileToggle = $el.find('.js-ps-navigation-mobile');
    this.$primaryNav = this.$el.find('.js-ps-primary-nav');
    this.$branding = this.$el.find('.ps-site-logo__title');
    this.toggleMobileClass = 'toggle-mobile';
    this.onScrollClass = 'on-scroll';
    this.maxWidth = 967;

    this.bindEvents();
  }

  bindEvents() {
    window.addEventListener('scroll', event => this.navScroll(event));
    this.$mobileToggle.on('click', event => this.toggleMobile(event));
    window.addEventListener('resize', event =>
      this.$el.hasClass(this.toggleMobileClass) && window.innerWidth > this.maxWidth
        ? this.closeMobile(event)
        : this.checkSize(event)
    );
  }

  removeInlineStyle() {
    setTimeout(() => {
      this.$primaryNav.removeAttr('style');
      this.$branding.removeAttr('style');
    }, 300);
  }

  toggleMobile(event) {
    if (this.$el.hasClass(this.onScrollClass)) {
      this.$el.removeClass(this.onScrollClass);
      this.removeInlineStyle();
    } else {
      if (this.$el.hasClass(this.toggleMobileClass)) {
        this.closeMobile();
      } else {
        this.openMobile();
      }
    }
  }

  checkSize(event) {
    if (
      window.innerWidth < this.maxWidth &&
      this.$el.hasClass(this.onScrollClass)
    ) {
      this.$el.removeClass(this.onScrollClass);
      this.removeInlineStyle();
    }
    return;
  }

  closeMobile() {
    this.$el.removeClass(this.toggleMobileClass);
    this.$mobileToggle.attr('aria-pressed', false);
  }

  openMobile() {
    this.$el.addClass(this.toggleMobileClass);
    this.$mobileToggle.attr('aria-pressed', true);
  }

  navScroll(event) {
    if ($(".navigation__tab-active").length) {
      return;
    }
    let offsetTop = window.pageYOffset;
    if (window.innerWidth > this.maxWidth) {
      if (offsetTop > 200) {
        if (this.$el.hasClass(this.onScrollClass)) {
          return;
        } else {
          this.$el.addClass(this.onScrollClass);
          this.$primaryNav.css('display', 'none');
          this.$branding.css('display', 'none');
        }
      } else {
        if (this.$el.hasClass(this.onScrollClass)) {
          this.$el.removeClass(this.onScrollClass);
          this.removeInlineStyle();
        }
      }
    }
  }
}
