import {connect} from "react-redux";
import SearchBox from "../../common/components/SearchBoxGray.jsx";
import {
  loadNewKeywords,
  keywordsChanged,
  loadSpecificItem,
  loadFacetFromQueryChange,
  loadFacetChange
} from "../../common/redux/search/search.actions";

const mapStateToProps = (state) => {
  return {
    keywords: state.query.q,
    dictionary: state.dictionary,
    loading: state.loading,
    isTypeFilter: true,
    isGray: true,
    facets: state.facets
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleEntry: (value) => {
      dispatch(loadNewKeywords(value));
    },
    handleKeywordChange: (value) => {
      dispatch(keywordsChanged(value));
    },
    handleSpecificItem: (id, keyword) => {
      dispatch(loadSpecificItem(id, keyword));
    },
    handleFacetFromQueryChange: (id, value) => {
      dispatch(loadFacetFromQueryChange(id, true, value)) // setting this to true because you can't unselect facets from box
    },
    handleFacetChange: (id) => {
      dispatch(loadFacetChange(id, true)) // setting this to true because you can't unselect facets from box
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
