import PropTypes from 'prop-types';
import React from "react";
import FacetGroup from "./FacetGroup.jsx";
import { allSelectedFacets } from "../../common/redux/facets";
import { closeFilters, setFiltersOpenDirection, manageContainerWhitespace, openFilters } from "../../common/filterSlider";
import SortBar from "../../common/components/SortBar.jsx";

export default class FacetList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		}
	}

	componentDidMount() {
		// setFiltersOpenDirection();
		// manageContainerWhitespace();
		window.addEventListener("scroll", () => this.onScroll());
	}

	clearAll() {
		this.props.handleClearAll();
	}

	applyAll() {
		this.toggleFilters();
	}

	toggleFilters() {
		let facetEl = document.querySelector(".search-results .search-facets");
		let toggleEl = document.querySelector(".search-results .search-facets__filter-toggle");
		this.setState({
			isOpen: !this.state.isOpen
		}, () => {
			if (this.state.isOpen) {
				facetEl.classList.add("is-open");
				toggleEl.classList.add("is-open");
			} else {
				facetEl.classList.remove("is-open");
				toggleEl.classList.remove("is-open");
			}
		})
	}

	renderHideFilters(facetsAreSelected) {
		return (
			<div className={`search-facets__filter-toggle ${this.state.isOpen ? "is-open" : ""}`}>
				<button type="button"
					className="btn--primary"
					onClick={() => this.toggleFilters()}>
					{this.props.dictionary.sortAndFilterLabel} {facetsAreSelected > 0 ? <span className="selected">{this.props.dictionary.selectedLabel}</span> : ""}
				</button>

			</div>
		);
	}

	onScroll() {
		let isMobile = window.innerWidth >= 768 ? false : true;
		let scrollTop = window.pageYOffset;
		if (isMobile && scrollTop > 190) {
			console.log("scroll mobile", isMobile);
			document.querySelector(".js-search-facets-container").classList.add("is-sticky")

		} else {
			document.querySelector(".js-search-facets-container").classList.remove("is-sticky")
		}
	}


	renderFacetGroups() {
		const facetsLength = this.props.facets.length;
		return this.props.facets.map((group, i) => {
			if (group.values.length === 0 && group.childGroups.length === 0) {
				return null;
			}
			const numOpenGroups = this.props.numOpenGroups;
			const isOpen = typeof numOpenGroups !== "undefined"
				&& numOpenGroups !== null
				&& i < numOpenGroups;
			return (<FacetGroup key={group.id}
				handleFacetChange={this.props.handleFacetChange}
				handleClearGroup={this.props.handleClearGroup}
				isOpen={isOpen}
				dictionary={this.props.dictionary}
				loading={this.props.loading}
				facets={this.props.facets}
				group={group}
				isLastGroup={facetsLength === i + 1} />);
		});
	}

	renderApplyAll() {
		return (
			<div className="search-facets__apply-all">
				<button type="button"
					onClick={() => this.applyAll()}>
					{this.props.dictionary.viewResultsLabel}
				</button>
			</div>
		);
	}

	renderClearAll() {
		return (
			<div className="search-facets__clear-all">
				<button type="button"
					onClick={() => this.clearAll()}>
					{this.props.dictionary.clearAllFiltersLabel}
				</button>
			</div>
		);
	}

	render() {
		const facetGroups = this.renderFacetGroups();
		const shouldRender = facetGroups.filter(x => x != null).length != 0;
		const facetsAreSelected = allSelectedFacets(this.props.facets).length > 0;

		return (shouldRender && 
			<div className={`js-search-facets-container ${facetsAreSelected ? "has-selected" : ""}`}>
				{this.renderHideFilters(facetsAreSelected)}
				{this.props.mobileFacetsShown ? <h3>{this.props.title}</h3> : null}
				<div className="search-facets">
					<h4 className="search-facets__mobile-label">{this.props.dictionary.sortLabel}</h4>
					<SortBar sorters={this.props.sorters} handleSortClick={this.props.changeSort} dictionary={this.props.dictionary} />
					<h4 className="search-facets__mobile-label">{this.props.dictionary.filtersLabel}</h4>
					<h4 className="search-facets__label">Filter your results</h4>
					{facetGroups}
					{this.renderApplyAll()}
					{(allSelectedFacets(this.props.facets).length > 0) && this.renderClearAll()}
				</div>
			</div>
		);
	}
}

const { string, arrayOf, bool, shape, number, oneOfType, func } = PropTypes;
FacetList.propTypes = {
	title: string,
	mobileFiltersShown: bool,
	hideMobileFacets: func,
	facets: arrayOf(shape({ id: oneOfType([number, string]) })),
	numOpenGroups: number,
	handleFacetChange: func.isRequired,
	handleClearGroup: func.isRequired,
	handleClearAll: func.isRequired
};

FacetList.defaultProps = {
	facets: [],
	numOpenGroups: null
};

