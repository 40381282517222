import PropTypes from 'prop-types';
import React from "react";
import $ from "jquery";
import LoadingSpinner from "./LoadingSpinner.jsx";
import { getConsultantSuggestions, getGeneralSuggestions } from "../../../utils/SearchSuggestionsAPI";
import { selectedFacetIdsByGroup } from "../redux/facets";

export default class TypeFilter extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        loading: false,
        suggestions: []
      };
  }

  componentWillMount() {
    this.timeout = null;
    clearTimeout(this.timeout);
    this.setState({loading: true});
    let selectedFacets = {};
    if (this.props.facets.length > 0) {
      selectedFacets = selectedFacetIdsByGroup(this.props.facets);

      // turn selected facets ids into comma delimited string
      Object.keys(selectedFacets).forEach(k => {
        selectedFacets[k] = selectedFacets[k].join(",");
      });
    }
    this.timeout = setTimeout(() => {
      (this.props.isGeneralSearch) ?
        getGeneralSuggestions(this.props.keywordInput)
          .then(res => {
            this.setState({
              suggestions: res.results || [],
              loading: false
            })
        })
      : getConsultantSuggestions(this.props.keywordInput, selectedFacets)
        .then(res => {
          this.setState({
            suggestions: res.results || [],
            loading: false
          })
      });
    }, 250);
  }

  componentWillReceiveProps(nextProps) {
    clearTimeout(this.timeout);
    if (this.props.keywordInput !== nextProps.keywordInput) {
      this.setState({loading: true});
      let selectedFacets = {};
      if (this.props.facets.length > 0) {
        selectedFacets = selectedFacetIdsByGroup(this.props.facets);

        // turn selected facets ids into comma delimited string
        Object.keys(selectedFacets).forEach(k => {
          selectedFacets[k] = selectedFacets[k].join(",");
        });
      }
      this.timeout = setTimeout(() => {
        (this.props.isGeneralSearch) ?
          getGeneralSuggestions(this.props.keywordInput)
            .then(res => {
              this.setState({
                suggestions: res.results || [],
                loading: false
              })
          })
        : getConsultantSuggestions(this.props.keywordInput, selectedFacets)
          .then(res => {
            this.setState({
              suggestions: res.results || [],
              loading: false
            })
        });
      }, 250)
    }
  }

  shouldCloseMenu(el) {
    if (!el) { return true; }
    return !$(this.dropdown).find(el).length;
  }

  handleChooseItem(event, id, value) {
    event.preventDefault();
    this.props.handleSpecificItem(`${id};autocomplete`, value);
  }

  handleChooseFacet(event, facetId, facetGroup, text) {
    event.preventDefault();
    this.props.handleFacetChange(facetId, text);
  }

  render() {
    const { suggestions, loading } = this.state;
    const hasSuggestions = suggestions.length > 0;
    return (
      <div className={`type-filter ${!hasSuggestions || loading ? "no-suggestions" : ""}`} ref={dropdown => this.dropdown = dropdown}
      onBlur={(event) => {this.props.handleBlur(event, this.shouldCloseMenu(event.relatedTarget))}} 
      onFocus={() => {this.props.handleFocus()}}>
      <div className="search-box__loader">
            { loading ? <LoadingSpinner/> : null }
        </div>
      {hasSuggestions && !loading &&
        <ul>
          {this.state.suggestions.map(
            (suggestion, index) => {
              return suggestion.filterValue ?
              <li key={index}>
                <button onClick={event => this.handleChooseFacet(event, suggestion.filterValue, suggestion.filterField, suggestion.text)}>
                  {suggestion.text}
                </button>
              </li>
              :
              <li key={index}>
                <button onClick={event => this.handleChooseItem(event, suggestion.id, suggestion.text)}>
                  {suggestion.text}
                </button>
            </li>
            }).filter((item, index) => index < 5)}

        </ul>
      }
     { hasSuggestions && !loading && <button type="submit" className="type-filter__view-all">{this.props.dictionary.viewAllResultsLabel}</button> }
      </div>
    );
  }
}

const { string, func, shape, bool } = PropTypes;

TypeFilter.propTypes = {
  keywordInput: string,
  handleEntry: func,
  dictionary: shape({
    searchBoxPlaceholder: string,
    searchBoxLabel: string
  })
};

