import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { isLastPage } from "../../common/paging";
import ResultItem from "./ResultItem.jsx";
import LoadMore from "../../common/components/LoadMore.jsx";
import LoadingSpinner from "../../common/components/LoadingSpinner.jsx";
import Pagination from "../../common/components/Pagination.jsx";
import {
  RESULTS_DISPLAY_REFRESH,
  RESULTS_DISPLAY_APPEND
} from "../../common/redux/resultsDisplay";

const SimpleResultList = ({
  results,
  resultsDisplayStrategy,
  totalResults,
  loading,
  isFirstLoad,
  nextPage,
  gotoPage,
  query,
  pagination,
  dictionary
}) => {
  const isLast = isLastPage(totalResults, query.perPage, query.page);
  const shouldShowNoResults = results.length === 0 && !isFirstLoad;
  const shouldShowPager = totalResults > query.perPage;

  function wrapperClass() {
    const isRefreshing =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_REFRESH;
    const isAppending =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_APPEND;
    return classnames("search-results search-results--simple", {
      "is-refreshing": isRefreshing,
      "is-appending": isAppending
    });
  }

  function resultsRange() {
    // Upper bound of visible results
    let ceiling = query.page * query.perPage;

    // Lower bound of visible results
    let floor = ceiling - query.perPage + 1;

    // make sure the ceiling does not exceed total results
    if (ceiling > totalResults) {
      ceiling = totalResults;
    }

    // if results are only one page, eschew the range entirely
    if (totalResults <= query.perPage) {
      return `${totalResults}`;
    }

    // if we're using load more, we want to show only
    // the ceiling of results displayed
    if (resultsDisplayStrategy === RESULTS_DISPLAY_APPEND) {
      return `${ceiling}`;
    }

    // otherwise, return the full range
    return `${floor} - ${ceiling}`;
  }

  function renderPagingControl() {
    if (pagination) {
      return (
        <Pagination
          totalResults={totalResults}
          resultsPerPage={query.perPage}
          currentPage={query.page}
          handlePageChange={gotoPage}
          dictionary={dictionary} />
      );
    }

    if (!pagination && !isLast) {
      return (
        <LoadMore
          handleClick={nextPage}
          dictionary={dictionary}
          loading={loading}
          showSpinner={false}
          buttonClass="btn btn--ghost"
        />
      );
    }
  }

  return (
    <section className={wrapperClass()}>
      <div className="search-results__item-wrapper">
        {loading && (
          <div className="search-results__loader">
            <LoadingSpinner />
          </div>
        )}
        <div className="search-results__items">
          {shouldShowNoResults ? (
            <p>No results found to match your query.</p>
          ) : null}
          {results.map(item => <ResultItem key={item.id} {...item} />)}
        </div>
      </div>
      {shouldShowPager ? renderPagingControl() : null}
    </section>
  );
};

const {
  arrayOf,
  string,
  object,
  func,
  bool,
  number,
  shape,
  oneOfType
} = PropTypes;

SimpleResultList.propTypes = {
  results: arrayOf(
    shape({
      id: oneOfType([number, string])
    })
  ),
  resultsDisplayStrategy: number,
  totalResults: number.isRequired,
  loading: bool,
  isFirstLoad: bool,
  nextPage: func,
  gotoPage: func,
  changeSort: func,
  query: shape({
    q: string,
    sortBy: string,
    page: number,
    perPage: number,
    pageId: string
  }),
  pagination: bool,
  dictionary: object
};

export default SimpleResultList;
