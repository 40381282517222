import $ from "jquery";

export function init() {
  initializeSlider();
  $(window).on("resize", initializeSlider);
}

var sliderIsActive = false;

function initializeSlider() {
  if (window.innerWidth >= 600) {
    sliderIsActive = false;
    return;
  }

  if (sliderIsActive) {
    return;
  }

  $(".js-featured-insights-slider").each(function () {
    $(this).slick({
      speed: 300,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 600,
          settings: "unslick"
        }
      ]
    });
  });

  sliderIsActive = true;
}
