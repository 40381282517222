import "core-js/features/object";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/fill";
import "core-js/features/array/includes";
import "core-js/features/array/from";
import "core-js/features/string/includes";
import "core-js/features/symbol/for";
import "core-js/features/promise";
import "core-js/features/promise";
import "core-js/features/string";
import "whatwg-fetch";

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
