import React from "react";
import R from "ramda";

import {
  withSearchId,
  withNode,
  withoutNode,
  withServiceApi,
  withQueryStringRouting,
  withoutQueryStringRouting,
  withInitialState,
  withInitialDispatch
} from "../common/factory";

import {
  initialState as defaultInitialState,
  applyExternalConfig,
  applyResultDisplayStrategy
} from "../common/redux/initialState";
import {
  fetchSearchResults as defaultFetchResults,
  mapResponseToState as defaultMapResponseToState
} from "../common/redux/service.js";
import { loadResults } from "../common/redux/search/search.actions";

import {
  mapStateToUrl as defaultMapStateToUrl,
  handleUrlChange as defaultHandleUrlChange
} from "../common/redux/routing";

import SearchResultsContainer from "./containers/SearchResultsContainer.jsx";
import SimpleResultsContainer from "./containers/SimpleResultsContainer.jsx";
import QueryContainer from "./containers/QueryContainer.jsx";
import FacetsContainer from "./containers/FacetsContainer.jsx";
import FilterBarContainer from "./containers/FilterBarContainer.jsx";

// Default search app factory function, without any React components
// specified. This is a good "base factory" to use if you want the
// "out-of-box" search config, but with custom components.
export const nodelessDefaultFactory = searchId =>
  R.pipe(
    withSearchId(searchId),
    withInitialState(
      defaultInitialState,
      R.pipe(
        applyExternalConfig(window.threadSearchConfigs, searchId),
        applyResultDisplayStrategy
      )
    ),
    withQueryStringRouting(defaultMapStateToUrl, defaultHandleUrlChange),
    withServiceApi(defaultFetchResults, defaultMapResponseToState),
    withInitialDispatch(loadResults)
  );

// Default "out-of-box" search page factory
export const consultantFacetedSearchFactory = searchId =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withNode(".js-consultant-query", <QueryContainer />),
    withNode(".js-consultant-results", <SearchResultsContainer />),
    withNode(".js-consultant-facets", <FacetsContainer />)
  );

// Factory for Dynamic Content Listing apps
export const consultantDynamicContentFactory = searchId =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withoutQueryStringRouting(),
    withNode(".js-dynamic-results", <SimpleResultsContainer />),
    withNode(".js-dynamic-filter-bar", <FilterBarContainer showSelected={true} />)
  );
