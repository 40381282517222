import $ from "jquery";
import moment from "moment";

export class CookiePolicy {
  constructor($el) {
    this.$el = $el;

    const dataset = $el[0].dataset;
    this.cookieName = dataset.cookieName;
    this.cookieDomain = dataset.cookieDomain;
    this.cookieLifetimeInMonths = dataset.cookieLifetimeInMonths;
    this.$cookieAcceptButton = $(dataset.cookieAcceptButtonSelector);

    this.bindEvents();
  }

  bindEvents() {
    this.$cookieAcceptButton.on("click", () => this.setCookie());
  }

  setCookie() {
    const expirationDate = moment().add(this.cookieLifetimeInMonths, 'M').toDate();
    document.cookie = `${this.cookieName}=yes;domain=${this.cookieDomain};expires=${expirationDate.toUTCString()};path=/`;
    this.$el.hide();
  }
}
