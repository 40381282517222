import PropTypes from 'prop-types';
import React from "react";
import LoadingSpinner from "./LoadingSpinner.jsx";

export default class LoadMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initiatedLoad: false
    };
  }

  onClick(event) {
    event.preventDefault();
    this.props.handleClick();
    this.setState({
      initiatedLoad: true
    });
  }

  render() {
    const { dictionary } = this.props;
    const shouldShowSpinner = this.props.showSpinner && this.props.loading && this.state.initiatedLoad;
    return (
      <div className="search-results__load-more">
        <button className={this.props.buttonClass} type="button" onClick={e => this.onClick(e)}>
          { dictionary.loadMoreLabel }
          { shouldShowSpinner ? <LoadingSpinner/> : null }
          </button>
      </div>
    );
  }

}

const { func, shape, string, bool } = PropTypes;

LoadMore.propTypes = {
  handleClick: func.isRequired,
  loading: bool,
  buttonClass: string,
  showSpinner: bool,
  dictionary: shape({
    loadMoreLabel: string
  })
};


LoadMore.defaultProps = {
  buttonClass: "btn btn--primary"
}
