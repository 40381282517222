
module.exports = function (window,document,$,undefined) {
	var debug = false;

	if (debug) { console.log('site-nav.js has successfully loaded!'); }

	var navClass = "js-site-nav",
		subNavClass = "js-sub-nav--target",
		triggerClass = "js-sub-nav--trigger",
		activeClass = "is-on",
		mobileActiveClass = "mobile-is-on",
		mobileTargetClass = "js-sub-nav-mobile--target",
		mobileTriggerClass = "js-sub-nav-mobile--trigger",
		hamburgerClass = "js-mobile-hamburger";

	var topNavClass = "js-top-links__nav",
		dropMenuClass = "js-drop-menu";

	// function to open sub-nav
	function openNav($target) {
		// close any currently open navs
		closeNav();

		// determine the appropriate position for the nav
		var $subNav = $target.children('.' + subNavClass);

		// then open the specified nav
		$target.addClass(activeClass);
	} // function openNav()

	// function to close sub-nav
	function closeNav() {
		// close any open navs
		$('.' + navClass).find('.' + activeClass).removeClass(activeClass);
	} // function closeNav()

	function moveTrigger($el){
		// get the links text
		var text = $el.text();
		// split text by spaces
		var subString = text.split(" ");
		// move the last word to new variable
		var lastword = subString.pop();
		// get the trigger object
		var $trigger = $el.parent().find('.' + triggerClass);
		// wrap the last word in new element
		var $element = $('<span class="sub-nav__trigger-wrapper">' + lastword + '&nbsp;</span>');
		// move the trigger to the new element
		$element.append($trigger);
		// replace the links html (text) with the first words and new element
		$el.html(subString.join(" ") + ' ').append($element);
	}

	// click event for sub-nav open/close
	function bindNavClicks() {
		var $el = $('.' + navClass),
			$subNavLink = $('.' + navClass).find('.' + triggerClass).prev();

		$subNavLink.each(function(){
			moveTrigger($(this));
		});
		// if a subnav is clicked
		$subNavLink.on('click',function(event){
			// if the target clicked is not the trigger
			if(!$(event.target).hasClass('js-sub-nav--trigger')){
				// allow the link to continue
				return true;
			}
			event.preventDefault();
			if(debug) { console.log("You've clicked on a sub nav expander!"); }

			var $targetElement = $(this).parent();

			if ($targetElement.hasClass(activeClass)) {
				/* 	If the menu is already open, close it*/
				closeNav();
			} else {
				/* otherwise, open it */
				openNav($targetElement);
			}
		});

		$el.on('click', '.' + mobileTriggerClass, function() {
			if(debug) { console.log("You've clicked on a subnav-subnav expander, which is really only for mobile!"); }
			var $clickedElement = $(this);

			if ($clickedElement.hasClass(activeClass)) {
				// close any open versions of this
				$('.' + mobileTriggerClass).removeClass(activeClass);
			} else {
				// close any open versions
				$('.' + mobileTriggerClass).removeClass(activeClass);
				// then open this specific one
				$clickedElement.addClass(activeClass);
			}
		});

		$('.' + hamburgerClass).on('click', function(){
			$('.' + navClass).toggleClass(mobileActiveClass);
		});
	} // function bindNavClicks


	function init() {
		bindNavClicks();
		// make all navigation items the same height.
		//$('.' + navClass).find('> ul > li > a').matchHeight();
	} // functions init()

	return {
		init: init
	};

}(window,document,jQuery);
