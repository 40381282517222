import { connect } from "react-redux";
import SearchResultList from "../components/SearchResultList.jsx";
import {
  loadNextPage,
  loadNewSort,
  loadNewPage,
  loadFacetChange,
  loadClearAllFacets,
  showMobileFacets
} from "../../common/redux/search/search.actions";
import { allSelectedFacets } from "../../common/redux/facets";

const mapStateToProps = state => {
  return {
    results: state.results,
    resultsDisplayStrategy: state.resultsDisplayStrategy,
    selectedFacets: allSelectedFacets(state.facets),
    totalResults: state.totalResults,
    loading: state.loading,
    isFirstLoad: state.isFirstLoad,
    sorters: state.sorters,
    query: state.query,
    pagination: state.pagination,
    dictionary: state.dictionary,
    popularSearches: state.popularSearches,
    facets: state.facets
  };
};

const mapDispatchToProps = dispatch => {
  return {
    nextPage: () => {
      dispatch(loadNextPage());
    },
    changeSort: sorters => {
      dispatch(loadNewSort(sorters));
    },
    gotoPage: pageNum => {
      dispatch(loadNewPage(pageNum));
    },
    dismissFacet: (facetId, group) => {
      dispatch(loadFacetChange(facetId, false, group));
    },
    showMobileFacets: () => {
      dispatch(showMobileFacets());
    },
    handleClearAll: () => {
      dispatch(loadClearAllFacets());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultList);
