import $ from "jquery";

export class ContactUs {
  constructor($el) {
    this.$el = $el;
    this.activeClassName = "ps-active";
    this.focusedClassName = "ps-focused";
    this.$inputs = this.$el.find("input, select");
    this.init();
    this.bindEvents();
  }

  init() {
    this.$inputs.each((_index, input) => {
      if (!input.dataset.scFieldName || !input.dataset.scFieldName.length) {
        return;
      }

      if (input.value.trim().length) {
        this.addClass(input, this.focusedClassName);
      }

      this.setValidationMessages(input);
    });
  }

  bindEvents() {
    this.$inputs.on("focus", event => {
      this.addClass(event.currentTarget, this.activeClassName);
      this.addClass(event.currentTarget, this.focusedClassName);
    });

    this.$inputs.on("blur", event => {
      this.removeClass(event.currentTarget, this.activeClassName);

      if (!event.currentTarget.value.trim().length) {
        this.removeClass(event.currentTarget, this.focusedClassName);
      }
    });
  }

  addClass(input, className) {
    $(this.getInputContainer(input)).addClass(className);
  }

  removeClass(input, className) {
    $(this.getInputContainer(input)).removeClass(className);
  }

  getInputContainer(input) {
    return $(input).closest(".ps-contact-us__field")[0];
  }

  setValidationMessages(input) {
    let fieldName = input.dataset.scFieldName.toLowerCase();

    if (input.dataset.valRequired && input.dataset.valRequired.length) {
      input.dataset.valRequired = `Your ${fieldName} is required.`;
    }

    if (input.dataset.valRegex && input.dataset.valRegex.length) {
      input.dataset.valRegex = `Your ${fieldName} wasn't formatted properly.`;
    }

    if (input.dataset.valIschecked && input.dataset.valIschecked.length) {
      input.dataset.valIschecked = input.dataset.valIschecked.replace("* ", "");
    }
  }
}
