import $ from "jquery";
export class QuickSearch {
  constructor($el, $triggerEl) {
    this.$el = $el;
    this.$trigger = $triggerEl;
    this.$textbox = $el.find(".js-textbox");
    this.bindEvents();
  }

  bindEvents() {
    this.$trigger.on("click", (event) => this.handleButtonClick(event));
  }

  handleButtonClick(event) {
    // if no input has been entered, the button will show/hide
    // the search box. If text is entered, it will submit the
    // search form
    if (!this.$textbox.val().trim().length) {
      event.preventDefault();
      this.toggleVisibility();
    }
  }

  toggleVisibility() {
    let tabs = document.querySelectorAll(".ps-navigation__tab ");
    this.$el.toggleClass("is-open");
    if (this.$el.hasClass("is-open")) {
      this.$textbox.focus();
    }
  }
}
