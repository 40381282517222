/** @module search/facets **/

/**
 * @typedef {Object} FacetValueShape
 * @property {Array} childValues Array of {@link FacetValueShape}
 * @property {number} count Number of available records
 * @property {string} id Identifier
 * @property {string} name Display Name
 * @property {boolean} selected Selection state
 */

/**
 * @typedef {Object} FacetGroupShape
 * @property {Array} childGroups Array of {@link FacetGroupShape}
 * @property {string} id Identifier for group

 * @property {string} label Display name for group
 * @property {array} values Array of {@link FacetValueShape}
 */

import R from "ramda";
import { flattenObj } from "../util";

/**
 * Extract all facet values from a facet group, including child groups and child values.
 * @param {FacetGroupShape} group The group to filter
 * @returns {Array<FacetValueShape>} List of extracted facet values
 */
export function allFacetValuesFromGroup(group) {
  let allValues = [];

  const grabAllValues = value => {
    allValues.push(value);
    if (value.childValues.length > 0) {
      value.childValues.forEach(cv => grabAllValues(cv));
    }
  };

  group.values.forEach(value => grabAllValues(value));

  if (group.hasOwnProperty("childGroups")) {
    group.childGroups.forEach(childGroup => {
      childGroup.values.forEach(value => grabAllValues(value));
    });
  }

  return allValues;
}

/**
 * Extract selected facet values, grouping by root facet group.
 * @param {Array<FacetGroupShape>} facets List of facet groups
 * @param {String} property the property of the group to use for the object key, default is group id
 * @returns {Object<string, Array<FacetValueShape>>} Lists of selected facet values, keyed by root group id.
 */
export function selectedFacetsByGroup(facets, property = "id") {
  let facetsByGroup = {};
  facets.forEach(group => {
    const selectedFacets = selectedFacetsFromGroup(group);
    if (selectedFacets.length) {
      facetsByGroup[group[property]] = selectedFacetsFromGroup(group);
    }
  });

  return facetsByGroup;
}

/**
 * Extract just the ids of select facet values from a facet group
 * @param {Array<FacetGroupShape>} facets List of facet groups
 * @returns {object<string, Array<string>>} Lists of selected ids, keyed by root group id.
 */
export function selectedFacetIdsByGroup(facets) {
  const selectedFacets = selectedFacetsByGroup(facets);
  Object.keys(selectedFacets).forEach(k => {
    selectedFacets[k] = selectedFacets[k].map(f => f.id);
  });

  return selectedFacets;
}

/**
 * Extract only selected facet values from a list of facet values. Inclusive of child facet values.
 * @param {Array<FacetValueShape>} values Facet value objects to filter
 * @returns {Array<FacetValueShape>} A flat list of selected facet values
 */
export function selectedFacetsFromValues(values) {
  let selected = values.filter(v => v.selected === true);
  values.forEach(value => {
    if (value.hasOwnProperty("childValues")) {
      selected = selected.concat(selectedFacetsFromValues(value.childValues));
    }
  });

  return selected;
}

/**
 * Extract only selected facet values from a facet group. Inclusive of child facet groups and child facet values.
 * @param {FacetGroupShape} values Facet group to filter
 * @returns {Array<FacetValueShape>} Only selected facet values
 */
export function selectedFacetsFromGroup(group) {
  let selected = selectedFacetsFromValues(group.values);
  if (group.hasOwnProperty("childGroups")) {
    group.childGroups.forEach(childGroup => {
      selected = selected.concat(selectedFacetsFromGroup(childGroup));
    });
  }
  selected = selected.map(value => {
    value.parent = group.id;
    return value;
  });

  return selected;
}

/**
 * Extract all facet value objects that are selected. Inclusive of all groups, subgroups and child values.
 * @param {Array<FacetGroupShape>} facets List of facet groups to be filtered
 * @returns {Array<FacetValueShape>} Only selected facet values
 */
export function allSelectedFacets(facets) {
  return facets.reduce((all, group) => {
    return all.concat(selectedFacetsFromGroup(group));
  }, []);
}

/**
 * Does a group have a facet value object that is selected?
 * @param {FacetGroupShape} group Facet group to be filtered
 * @returns {boolean}
 */
export function groupHasSelectedChildren(group) {
  return selectedFacetsFromGroup(group).length > 0;
}

/**
 * Does a facet value object have a child facet value that is selected?
 * @param {FacetGroupShape} group Facet group to check
 * @returns {boolean}
 */
export function valueHasSelectedChildren(value) {
  // does not include selection state of group itself
  let selectedChildren = [];
  if (value.hasOwnProperty("childValues")) {
    selectedChildren = selectedFacetsFromValues(value.childValues);
  }

  return selectedChildren.length > 0;
}

/**
 * Extract facets value objects from a group which has a child value that is selected.
 * The parent facet value does not need to be selected in order to be chosen.
 * @param {FacetGroupShape}group Facet group to check
 * @returns {Array<FacetValueShape>}
 */
export function valuesWithSelectedChildren(group) {
  const all = allFacetValuesFromGroup(group);
  return all.reduce((hasSelected, value) => {
    if (valueHasSelectedChildren(value)) {
      hasSelected.push(value);
    }

    return hasSelected;
  }, []);
}

// TODO: This performs acceptably for now
// but is definitely not optimal for very large data sets.
// Should consider a different approach to setting deeply nested data.
/**
 * Returns an array of object path segments that
 * resolves to the facet value object in the
 * provided facets state object. This is useful
 * when needing to make immutable changes using
 * R.assocPath or R.lensPath
 * @param {string} valueId
 * @param {Array<FacetGroupShape>} facets List of facet groups
 * @returns {Object<string, FacetValueShape>}
 */
export function pathFromValueId(valueId, facets, group) {
  // First, flatten the facets object into k,v pairs of properties
  // and stringified "path" keys. Then use the paths to
  // lookup the facet value
  const facetLookup = R.invertObj(flattenObj(facets));
  let pathArr = [];
  if (group) {
    const facetInGroupLookup = R.invertObj(flattenObj(group));
    const groupPath = facetLookup[group.label];
    const facetInGroupPath = facetInGroupLookup[valueId];
    let groupPathArr = groupPath.split(".");
    let facetInGroupPathArr = facetInGroupPath.split(".");

    groupPathArr.pop();
    pathArr = R.concat(groupPathArr, facetInGroupPathArr);

  } else {

    const delimitedPath = facetLookup[valueId];
    // Our paths are in format "a.b.0.c', so convert into ["a", "b", 0, "c"].
    // Then pop off the last path segment so it points to the entire value
    // object, instead of just the id.
    pathArr = delimitedPath.split(".");
  }

  pathArr.pop();

  // Finally, ensure numerical "keys" are properly cast to Numbers
  // or else Ramda will treat them as object keys and
  // convert arrays to objects when using assocPath or lens methods.
  pathArr = pathArr.map(segment => {
    const numberified = parseInt(segment, 10);
    return isNaN(numberified) ? segment : numberified;
  });

  return pathArr;
}

/**
 * Create a new facets array with the specified value objects set the desired selected state
 * @param {Array<string>} values The ids of the value object we want to affect; this can be an object or string of ids
 * @param {boolean} selected State of the selected property
 * @param {Array<FacetGroupShape>} facets List of facet groups
 * @returns {Array<FacetGroupShape>} The new facets array
 */
export function setSelectedOnFacetValues(values, selected, facets, group) {
  // Lookup the path for the given facetId
  // and change it using assocPath

  if (!values.length) {
    return facets;
  }

  const paths = values.map(value => {
    let pathArr = [];
    if (values.every(v => typeof v !== "string")) {
      const group = R.find(R.propEq("id", value.parent), facets);
      pathArr = pathFromValueId(value.id, facets, group);
    } else {
      pathArr = pathFromValueId(value, facets, group);
    }
    // add "selected" to the end of our path
    pathArr.push("selected");

    return pathArr;
  });

  return paths.reduce((newFacets, path) => {
    // NOTE: Using numerical indexes in assocPath only work in
    // Ramda 0.23.0+
    return R.assocPath(path, selected, newFacets);
  }, facets);
}
