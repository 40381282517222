/** @module search/components */

import React from "react";
import PropTypes from 'prop-types';
import R from "ramda";
import classnames from "classnames";
import URI from "urijs";
import {facetGroupShape, facetValueShape} from "../../common/dataShapes";
import $ from "jquery";

import {
  valuesWithSelectedChildren,
  selectedFacetsFromGroup,
  selectedFacetsFromValues,
  selectedFacetsByGroup,
  allSelectedFacets
} from "../../common/redux/facets";

const MAX_FACETS_SHOWN = Infinity;

/**
 * 
 * An accordion like section for a single grouping of facets
 * ```
 *  <FacetGroup
      group={group}
      isOpen={false}
      handleFacetChange={func}
      handleClearAll={func} />
 * ```
 * @component
 * @param {object} props
 * @param {bool} props.isOpen Flag for the open state of the accordion
 * @param {object} props.group The group of facets to show
 * @param {function} props.handleFacetChange Function called when a facet is selected/deselected
 * @params {function} prop.handleClearGroup Function called when clearing all selected facets
 */

class FacetGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGroupOpen: this.props.isOpen,
      isGroupExpanded: false,
      valuesExpanded: []
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleGroup = this.toggleGroup.bind(this);
  }

  // Lifecycle

  componentWillMount() {
    // if group is closed, but has selected
    // values, open it
    const group = this.props.group;

    const selected = selectedFacetsFromGroup(group);
    let shouldOpen = this.state.isGroupOpen;
    if (selected.length > 0) {
      shouldOpen = true;
    }

    // Some facets will be expanded by default due to
    // children being pre-selected
    const hasSelected = valuesWithSelectedChildren(group);
    const selectedIds = hasSelected.map( v => v.id);

    // similarly, if group's values or value children
    // are selected, auto-expand that group
    const tailValues = R.drop(MAX_FACETS_SHOWN, group.values);
    const shouldExpand = tailValues.reduce( (should, tv) => {
      if (tv.selected === true || selectedIds.indexOf(tv.id) >= 0) {
        should = true;
      }

      return should;

    }, false);


    this.setState({
      // isGroupOpen: shouldOpen,
      isGroupExpanded: shouldExpand,
      valuesExpanded: selectedIds
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ isGroupOpen: newProps.isOpen }, () => {
      if (!this.state.isGroupOpen) {
        document.removeEventListener('click', this.handleClickOutside, false);
      }
    });
  }


  chunkValues(values) {
    // divide the values into two arrays, the first for the Top X facets
    // and the second for overflow. If recursion level is over zero, put
    // all values in first array.
    return values.length > MAX_FACETS_SHOWN
        ? R.splitAt((MAX_FACETS_SHOWN), values)
        : [values, []];
  }

  showExpandButton() {
    return !this.state.isGroupExpanded;
  }

  shouldRenderTailValues(tailValues) {
    return this.state.isGroupExpanded
      && tailValues.length > 0;
  }

  shouldRenderChildGroups(group) {
    return group.isOpen
      && !!group.childGroups
      && group.childGroups.length > 0;
  }

  handleClickOutside(e) {
    if ((!$(this.facetGroupTop).find(e.target).length) && !$(e.target).is('input[type="checkbox"]')) {
      this.toggleGroup();
    }
  }

  toggleGroup() {
    const isOpen = !this.state.isGroupOpen;
    this.setState({ isGroupOpen: isOpen });
    this.props.handleOpenGroup(this.props.group.id, isOpen);
    if (isOpen) {
      document.addEventListener('click', this.handleClickOutside, false);
    } else {
      document.removeEventListener('click', this.handleClickOutside, false);
    }
  }

  toggleExpand() {
    const isExpanded = !this.state.isGroupExpanded;
    this.setState({ isGroupExpanded: isExpanded });
  }

  toggleValueExpand(valueId) {
    // check if the valueId is in the list. Remove it
    // if found, add it if not.
    const valueIdx = this.state.valuesExpanded.indexOf(valueId);
    const selectedValues = valueIdx >= 0
      ? R.remove(valueIdx, 1, this.state.valuesExpanded)
      : R.append(valueId, this.state.valuesExpanded);

    this.setState({ valuesExpanded: selectedValues });
  }

  valueIsExpanded(value) {
    return this.state.valuesExpanded.indexOf(value.id) >= 0;
  }

  onFacetChange(event, valueId) {
    const { group, facets } = this.props;
    const decodedValue = URI.decodeQuery(valueId).split("/").pop();

    this.props.handleFacetChange(valueId, event.target.checked, group);
    
    let selectedSpecialtiesFacets = selectedFacetsFromGroup(R.find(R.propEq("id", "specialties"), facets)).map(v => v.name);
    let selectedOfficeFacets = selectedFacetsFromGroup(R.find(R.propEq("id", "offices"), facets)).map(v => v.name);

    if (group.id === "specialties") {
      selectedSpecialtiesFacets = event.target.checked 
      ? R.append(decodedValue, selectedSpecialtiesFacets)
      : R.remove(R.indexOf(decodedValue, selectedSpecialtiesFacets), 1, selectedSpecialtiesFacets);
    } else {
      selectedOfficeFacets = event.target.checked 
      ? R.append(decodedValue, selectedOfficeFacets)
      : R.remove(R.indexOf(decodedValue, selectedOfficeFacets), 1, selectedOfficeFacets);
    }
    
    // GTM Tracking Analytics
    window.datalayer = window.datalayer || [];
    window.dataLayer.push({
      'event' : 'consultantSearchDropdown',
      'consultant_search_location' : `${R.join("|", selectedOfficeFacets)}`,
      'consultant_search_specialty' : `${R.join("|", selectedSpecialtiesFacets)}`,
    });
  }

  applyAll() {
    this.toggleGroup();
  }

  clearAll() {
    this.onClearGroup();
    this.toggleGroup();
  }

  onClearGroup() {
    const group = this.props.group;
    this.props.handleClearGroup(group);
  }

  renderApplyAll() {
    return (
      <div className="search-facets__apply-all">
        <button type="button"
                onClick={() => this.applyAll() }>
          {this.props.dictionary.viewResultsLabel}
        </button>
      </div>
    );
  }

  renderClearAll() {
    return (
      <div className="search-facets__clear-all">
        <button type="button"
                onClick={() => this.clearAll() }>
          {this.props.dictionary.clearAllFiltersLabel}
        </button>
      </div>
    );
  }

  // Rendering
  renderOpenButton() {
      const hasSelectedFacets = selectedFacetsFromGroup(this.props.group).length > 0;
      const shouldShowGroupLabel = true; //selectedFacetsFromGroup(this.props.group).length !== this.props.group.values.length;
      const openClass = classnames("search-facets__group-toggle", { "is-open": this.state.isGroupOpen, "has-selected" : hasSelectedFacets });
      return this.props.isStatic ?
        shouldShowGroupLabel ? 
        <div className="search-facets__group-heading">
          <h4 className="group-label">{this.props.group.label}</h4>
        </div> : null
        : <div className="search-facets__group-heading text-center">
           <button className={openClass} type="button" onClick={(e) => this.toggleGroup(e)} disabled={this.props.isDisabled}>
             <h3>
               <span className="u-visuallyhidden">{this.props.dictionary.toggleLabel} </span>{this.props.group.label} {hasSelectedFacets > 0 ? <span className="selected">{this.props.dictionary.selectedLabel}</span> : ""}
             </h3>
           </button>
         </div>
  }


  renderValueItem(value, recursionLevel) {
    const { loading, isStatic } = this.props;
    const childValues = value.childValues;
    const hasChildValues = !!childValues && childValues.length > 0;
    const isExpanded = this.valueIsExpanded(value);
    const renderChildValues = hasChildValues && isExpanded;
    const toggleClass = classnames("search-facets__value-expand", { "is-open": isExpanded});

    return (
      <li key={value.id}>
        <input id={value.id} checked={value.selected}  onChange={ (e) => this.onFacetChange(e, value.id) } type="checkbox" disabled={loading}/>
        <label htmlFor={value.id}>{value.name}</label>
        {/* {hasChildValues && (
          <button type="button" className={toggleClass} onClick={ () => this.toggleValueExpand(value.id)}>
            <svg className="icon">
              <use xlinkHref="/images/svg-sprite.svg#chevron-down"></use>
            </svg>
          </button>
        )} */}
        {/* {renderChildValues && this.renderValues(childValues, recursionLevel + 1) } */}
      </li>
    );
  }

  renderFlatValues(values) {
    const { group, loading } = this.props
    const childGroups = group.childGroups;
    const renderValue = (value) => {
      return (
        <li key={value.id}>
          <input id={value.id} checked={value.selected}  onChange={ (e) => this.onFacetChange(e, value.id) } type="checkbox" disabled={loading}/>
          <label htmlFor={value.id}>{value.name}</label>
        </li>
      );
    };

    const renderFacetsWithNoGroup = () => {
      const groupValues = selectedFacetsFromValues(group.values);
      return (
      <ul className="search-facets__group-values">
        { groupValues.map( value => renderValue(value)) }
      </ul>
      )
    }

    const renderFacetsWithGroup = () => {
      const groupsWithSelectedValues = selectedFacetsByGroup(childGroups, "label");
      return Object.keys(groupsWithSelectedValues).map((key, i) => {
        return (
          <div key={key}>
            <div className="search-facets__group-heading">
              <h4 className="group-label">{key}</h4>
            </div>
            <ul className="search-facets__group-values">
              { groupsWithSelectedValues[key].map( value => renderValue(value)) }
            </ul>
          </div>
        );
      })
    }

    if (childGroups.length > 0) {
      return (
        <div>
          {renderFacetsWithNoGroup()}
          {renderFacetsWithGroup()}
        </div>
      )
    } else {
      return (
        <ul className="search-facets__group-values">
          { values.map( value => renderValue(value)) }
        </ul>
      );
    }
    

    
  }

  renderValues(values, recursionLevel) {
    let chunkedValues;
    if (recursionLevel === 0){
      chunkedValues = this.chunkValues(values);
    } else {
      chunkedValues = [values, []];
    }

    const headValues = chunkedValues[0];
    const tailValues = chunkedValues[1];

    const stateClass = `search-facets__group-values is-level-${recursionLevel}`;
    const valuesWrapperClass = classnames("search-facets__values-wrapper", { "has-no-values": !values.length });
    const shouldRenderTailValues = this.shouldRenderTailValues(tailValues);

    return (
      <div className={valuesWrapperClass}>
        <ul className={stateClass}>
          {headValues.map( val => this.renderValueItem(val, recursionLevel) )}
        </ul>
        { shouldRenderTailValues && (
          <ul className={classnames(stateClass, "is-overflow")}>
            {tailValues.map( val => this.renderValueItem(val, recursionLevel) )}
          </ul>
        )}
      </div>
    );
  }

  renderChildGroups(group) {
    if (this.state.isGroupOpen
        && group.childGroups
        && group.childGroups.length) {

      return group.childGroups.map( (childGroup, index) => {
        if (childGroup.values.length === 0) {
          return null;
        }

        return (<FacetGroup key={childGroup.id || `childGroup-${index}`}
                            group={childGroup}
                            dupeSelected={false}
                            handleFacetChange={this.props.handleFacetChange}
                            handleClearGroup={this.props.handleClearGroup}
                            dictionary={this.props.dictionary}
                            isStatic={true}
                            facets={this.props.facets}
                            loading={this.props.loading}
        />);
      });
    }
  }

  render() {
    const { group, isStatic, loading, dictionary } = this.props;
    const selectedValues = selectedFacetsFromGroup(group);
    const showDupeSelected = selectedValues.length > 0
      && this.props.dupeSelected;
    const groupClass = classnames("search-facets__group", {
      "is-open": this.state.isGroupOpen
    });
    const expandLabel = this.state.isGroupExpanded
      ? this.props.dictionary.showLessLabel
      : this.props.dictionary.showMoreLabel;

    return (
      <div className={groupClass} ref={facetGroupTop => this.facetGroupTop = facetGroupTop}>
          {this.renderOpenButton()}
          <div className={`search-facets__group-wrapper ${(loading && !isStatic) ? "is-loading" : ""}`}>
          {this.state.isGroupOpen && (
            <div className="search-facets__group-body">
              { selectedValues.length > 0 && (
                <div className="search-facets__group-top">
                  <button
                    onClick={ () => this.onClearGroup()}
                    className="search-facets__group-clear"
                    type="button">
                    {dictionary.clearAllLabel}
                  </button>
                </div>
              )}
              {/* {showDupeSelected && (
                <div className="search-facets__group-selected">
                  {this.renderFlatValues(selectedValues)}
                </div>
              )} */}
              {this.renderValues(group.values, 0)}
              {/* {group.values.length > MAX_FACETS_SHOWN && (
                <div className="search-facets__group-more">
                  <button type="button" onClick={() => this.toggleExpand()}>{expandLabel}</button>
                </div>
              )} */}
              {this.renderChildGroups(group)}
            </div>
          )}
          
          {!isStatic && this.renderApplyAll()}
          {(selectedValues.length > 0) && !isStatic && this.renderClearAll()}
        </div>
      </div>
    );
  }
}

export default FacetGroup;

const { func, shape, bool, number, string, oneOfType, arrayOf } = PropTypes;

FacetGroup.propTypes = {
  isOpen: bool,
  isDisabled: bool,
  dupeSelected: bool,
  handleFacetChange: func.isRequired,
  handleClearGroup: func.isRequired,
  group: facetGroupShape
};

FacetGroup.defaultProps = {
  isOpen: true,
  dupeSelected: true
};
