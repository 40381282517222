import $ from "jquery";
import {
  getConsultantSuggestions,
  getGeneralSuggestions,
} from "./utils/SearchSuggestionsAPI";
import URI from "urijs";

const watchClickAway = (el, callback) => {
  const handleKeyEvent = (event) => {
    if (event.key === "Escape") {
      unBindEvents();
      callback(true); // true => focus needs to be moved
    }
  };

  const handleClickEvent = (event) => {
    if (!el.contains(event.target)) {
      unBindEvents();
      callback(false);
    }
  };

  const bindEvents = () => {
    document.addEventListener("keydown", handleKeyEvent);
    document.addEventListener("click", handleClickEvent);
    document.addEventListener("focusin", handleClickEvent);
  };

  const unBindEvents = () => {
    document.removeEventListener("keydown", handleKeyEvent);
    document.removeEventListener("click", handleClickEvent);
    document.removeEventListener("focusin", handleClickEvent);
  };

  bindEvents();
};

export class NavSearch {
  constructor($el) {
    this.$el = $el;
    this.$navTabs = $el.find(".js-navigation__tab");
    this.$navSearch = $el.find(".js-navigation__search");
    this.$navToggle = this.$navSearch.find(".js-navigation__title");
    this.$navBox = this.$el.find(".js-navigation__search-box"); // array of search boxes
    this.uri = new URI(window.location.href);
    this.hasKeyword = this.uri.hasQuery("q", true);
    this.timeout = null;
    this.bindEvents();
  }

  bindEvents() {
    if (this.hasKeyword) {
      this.$navSearch.find(".js-search-input").val(this.uri.search(true).q);
    }
    this.$navToggle.on("click", (event) => this.toggle(event));

    // For each search box, handle typeahead dropdown
    this.$navBox.toArray().map((box) => {
      const $searchInput = $(box).find(".js-search-input");
      $searchInput.on("input", (event) => this.searchKeyword(event));
      $searchInput.on("focus", (event) => this.handleInputFocus(event, box));
      $(box).on("focusout", (event) => this.handleInputBlur(event, box));
    });
  }

  /**
   * Makes a service call if the value of the input element is not null,
   * then displays the results in a dropdown menu.  If the value is null,
   * hides the results menu
   * @param {Object} event the event that triggered the function
   */
  searchKeyword(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (event.currentTarget.value !== "") {
        const typeFilter = $(event.currentTarget).siblings(".js-type-filter");

        const populateList = (items) => {
          const str = items
            .map((item, i) => {
              return i < 5
                ? `<li><a href=${item.url}>${item.text}</a></li>`
                : null;
            })
            .join("");
          typeFilter.find("ul").html(str);
          items.length ? this.showResults(event) : this.hideResults(typeFilter);
        };

        $(typeFilter).attr("data-type") === "consultant"
          ? getConsultantSuggestions(event.currentTarget.value).then((res) =>
            populateList(res.results),
          )
          : getGeneralSuggestions(event.currentTarget.value).then((res) =>
            populateList(res.results),
          );
      } else {
        this.hideResults($(event.currentTarget).find(".js-type-filter"));
      }
    }, 250);
  }

  /**
   * Adds the class 'focused-within' to the top level search box element
   * and redisplays the results dropdown if the value of the input is already filled
   * @param {Object} event the event that triggered the function
   * @param {Node} box the top level search box element
   */
  handleInputFocus(event, box) {
    $(box).addClass("focused-within");
    if (event.currentTarget.value !== "") {
      this.searchKeyword(event);
    }
  }

  /**
   * Removes the class 'focused-within' from the top leevl search box element
   * and hides the results dropdown
   * @param {*} event the event that triggered the function
   * @param {*} box the top leel search box element
   */
  handleInputBlur(event, box) {
    if (!$(box).find(event.relatedTarget).length) {
      this.hideResults($(event.currentTarget).find(".js-type-filter"));
      $(box).removeClass("focused-within");
    }
  }

  /**
   * Displays the results dropdown
   * @param {*} event the event that triggered the function
   */
  showResults(event) {
    $(event.currentTarget)
      .siblings(".js-type-filter")
      .slideDown();
    $(event.currentTarget)
      .siblings(".js-type-filter")
      .addClass("is-open");
  }

  /** Hides the results dropdown
   * @param {Object} event the element to hide
   */
  hideResults(el) {
    $(el).slideUp();
    $(el).removeClass("is-open");
  }

  /**
   * Hide/show the global search bar
   * @param {Object} event the event that triggered the function
   */
  toggle(event) {
    event.preventDefault();
    if (this.$navToggle.hasClass("is-open")) {
      this.$navToggle.removeClass("is-open");
      this.$navSearch.removeClass("is-open");
      this.$navTabs.delay(500).fadeIn();
    } else {
      const self = this;
      this.$navTabs.fadeOut(200, function() {
        if (self.$navTabs.last().is(this)) {
          self.$navTabs.hide();
          self.$navToggle.addClass("is-open");
          self.$navSearch.addClass("is-open");
          setTimeout(function() {
            self.$navSearch.find(".js-search-input").focus();
          }, 200);
        }
      });

      watchClickAway(self.$navSearch[0], () => {
        this.$navToggle.removeClass("is-open");
        this.$navSearch.removeClass("is-open");
        this.$navTabs.delay(500).fadeIn();
      });
    }
  }
}
