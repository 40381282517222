import R from "ramda";
import * as RA from "ramda-adjunct";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import $ from "jquery";
import { TruncateText } from "../../TruncateText";
import * as util from "../../common/util";

export default class ResultItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemWrapper = null;
    this.state = {
      maxLength: window.innerWidth < 481 ? "120" : "210",
      runOnce: false
    }
  }

  componentDidMount() {
    this.itemWrapper.className = this.itemWrapper.className + " is-visible";
    this.truncateText();
    $(window).on("resize", () => {
      if (window.innerWidth < 481 && !this.state.runOnce) {
        this.setState({
          maxLength: "120",
          runOnce: true
        }, () => this.truncateText(this.props.body, this.state.maxLength))
      } else if (window.innerWidth >= 480 && this.state.runOnce) {
        this.setState({
          maxLength: "210",
          runOnce: false
        }, () => this.truncateText(this.props.body, this.state.maxLength))
      }
    });
  }

  truncateText(text = "", count = null) {
    //Truncate Text
    const $textToTruncate = $(this.bodyText);
    if ($textToTruncate.length) {
      const truncatedText = $.makeArray($textToTruncate).map(el => {
        return new TruncateText($(el), text, count);
      });
    }
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    return moment(date).format("MMMM Do, YYYY");
  }

  renderImage() {
    const { image1x1, contentUrl, title } = this.props;
    if (image1x1) {
      return (
        <figure className="list-item__media">
          <a href={contentUrl} target={util.getLinkTarget(contentUrl)}>
            <img src={image1x1} alt={title} />
          </a>
        </figure>
      );
    }
  }

  renderEyebrow() {
    const { contentType, date, location } = this.props;
    const itemPairs = R.compose(
      R.splitEvery(2),
      R.intersperse("|"),
      RA.compact
    )([contentType, date, location]);

    if (!itemPairs.length) {
      return;
    }

    const items = RA.mapIndexed(
      ([val, sep], idx) => (
        <React.Fragment key={idx}>
          <span className="list-item__eyebrow-item">{val}</span>
          {sep && <span className="list-item__eyebrow-separator">{sep}</span>}
        </React.Fragment>
      ),
      itemPairs
    );

    return <h2 className="list-item__eyebrow">{items}</h2>;
  }

  render() {
    const {
      title,
      body,
      contentType,
      date,
      authors,
      displayUrl,
      contentUrl,
      subtitle,
      phone,
      location,
      image1x1
    } = this.props;

    const formattedAuthors = R.compose(
      RA.mapIndexed((a, i) => <span key={i}>{a}</span>),
      R.intersperse(", ")
    )(authors ? authors : []);

    const shouldShowMeta = subtitle || formattedAuthors || date || phone;
    const isConsultant = contentType === "consultant";



    return (
      <article className="list-item is-right" ref={el => (this.itemWrapper = el)}>
        {this.renderImage()}
        <div className="list-item__content">
          <header className="list-item__header">
            <h3 className="list-item__title">
              <a href={contentUrl} target={util.getLinkTarget(contentUrl)}>{title}</a>
            </h3>
            {
              shouldShowMeta && (
              <aside className="list-item__meta">
                <span className="list-item__byline">
                {subtitle && <span>{subtitle}</span>}
                {!isConsultant && (formattedAuthors.length > 0) && <span>{formattedAuthors.map(author => author)}</span>}
                {!isConsultant && date && <span>{date}</span>}
                {!isConsultant && phone && <span><a href={`tel:${phone}`} className="list-item__phone">{phone}</a></span>}
                </span>
              </aside>
              )
            }
          </header>
          {body && <p data-maxlength={this.state.maxLength} className={`list-item__description js-truncate ${isConsultant ? "mobile-visible" : ""}`} ref={bodyText => this.bodyText = bodyText }>{body}</p>}
        </div>
      </article>
    );
  }
}

const { arrayOf, string } = PropTypes;
ResultItem.propTypes = {
  date: string,
  authors: arrayOf(string),
  title: string.isRequired,
  body: string,
  contentUrl: string.isRequired,
  displayUrl: string,
  contentType: string,
  location: string,
  image1x1: string
};
