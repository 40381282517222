import PropTypes from 'prop-types';
import React from "react";
import FacetGroup from "./FacetGroup.jsx";
import {closeFilters, setFiltersOpenDirection, manageContainerWhitespace} from "../../common/filterSlider";

export default class FacetList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openGroup: null
    }
  }

  componentDidMount() {
    // setFiltersOpenDirection();
    // manageContainerWhitespace();
  }

  clearAll() {
    this.props.handleClearAll();
  }

  hideFilters() {
    closeFilters(); // DOM ops
    this.props.hideMobileFacets(); // action creator
  }

  toggleGroup(id, isOpen) {
    this.setState({ openGroup: isOpen ? id : null });
  }

  renderHideFilters() {
    return (
      <div className="search-facets__filter-close">
        <button type="button"
                className="btn--primary"
                onClick={ () => this.hideFilters() }>
            {this.props.title}
          <svg className="icon">
            <use xlinkHref="/images/svg-sprite.svg#close"></use>
          </svg>
        </button>

      </div>
    );
  }

  renderFacetGroups() {
    return this.props.facets.map( (group, i) => {
      // if (group.values.length === 0 && group.childGroups.length === 0) {
      //   return null;
      // }
      const numOpenGroups = this.props.numOpenGroups;
      const isOpen = this.state.openGroup === group.id;
      const isDisabled = group.values.length === 0 && group.childGroups.length === 0;
      return (<FacetGroup key={group.id}
                          handleFacetChange={this.props.handleFacetChange}
                          handleClearGroup={this.props.handleClearGroup}
                          handleOpenGroup={(id, isOpen) => this.toggleGroup(id, isOpen)}
                          isOpen={isOpen}
                          isDisabled={isDisabled}
                          dictionary={this.props.dictionary}
                          loading={this.props.loading}
                          facets={this.props.facets}
                          group={group}/>);
    });
  }

  renderClearAll() {
    return (
      <div className="search-facets__clear-all">
        <button type="button"
                onClick={() => this.clearAll() }>
          Clear Filters
        </button>
      </div>
    );
  }

  render() {
    const facetGroups = this.renderFacetGroups();
    return (
      <div>
        {/* {this.renderHideFilters()} */}
        {this.props.mobileFacetsShown ? <h3>{this.props.title}</h3> : null }
        <div className="search-facets search-facets--dropdown-style">
          {/* {this.renderClearAll()} */}
          {facetGroups}
          {/* {this.renderClearAll()} */}
        </div>
      </div>
    );
  }
}

const { string, arrayOf, bool, shape, number, oneOfType, func } = PropTypes;
FacetList.propTypes = {
  title: string,
  mobileFiltersShown: bool,
  hideMobileFacets: func,
  facets: arrayOf(shape({ id: oneOfType([number, string]) })),
  numOpenGroups: number,
  handleFacetChange: func.isRequired,
  handleClearGroup: func.isRequired,
  handleClearAll: func.isRequired
};

FacetList.defaultProps = {
  facets: [],
  numOpenGroups: null
};

