module.exports = function (window, document, $, undefined) {
	var triggerWidth = 768,
		sliderActiveClass = "is-slider",
		theSlider,
		$target;

	function toggleSlider() {
		var windowSize = $(window).width(),
			classIsActive = $target.hasClass(sliderActiveClass);

		if (classIsActive && windowSize >= triggerWidth) {
			destroySlider();
		} else if (!classIsActive && windowSize < triggerWidth) {
			createSlider();
		}
	}

	function createSlider() {
		theSlider = $target.bxSlider({
			minSlides: 1,
			maxSlides: 3,
			moveSlides: 1,
			slideWidth: 370,
			slideMargin: 50,
			useCss: false,
			infiniteLoop: false
		});

		$target.addClass(sliderActiveClass);
	}

	function destroySlider() {
		theSlider.destroySlider();
		$target.removeClass(sliderActiveClass);
	}

	function init() {
		$(window).on("load", function () {
			$target = $(".js-mobile-slider");
			if (!$target.length) {
				return;
			}

			toggleSlider();

			$(window).on("resize", function () {
				toggleSlider();
			});
		});
	}

	return {
		init: init
	};

}(window, document, jQuery);
