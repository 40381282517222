module.exports = function (window,document,$,undefined) {
	function init() {
		$(".wb-categories ul").wrap('<div class="js-blog-cat-drop blog-cat-drop"></div>');
		$(".js-blog-cat-drop").prepend('<div class="js-blog-cat-drop__trigger blog-cat-drop__trigger">All Categories</div>');
		$('.wb-categories').on("click", ".js-blog-cat-drop__trigger", function() {
			$(this).toggleClass("is-active");
			$(this).siblings("ul").toggle();
		});
	}

	return {
		init: init
	};

}(window,document,jQuery);
