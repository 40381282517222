import $ from "jquery";

const className = "js-mvc-integration-iframe";
const iframeClassName = "mvc-integration-iframe__iframe";
const initializedClassName = "mvc-integration-iframe--initialized";

$(function () {
	const $el = $(`.${className}`);
	const $iframe = $el.find(`.${iframeClassName}`);
	
	if ($iframe.length) {
		document.body.style.overflowX = "hidden";
		
		$iframe.each(function (_index, iframe) {
			bindEvents(iframe);
		});
	}
});

const bindEvents = iframe => {
	iframe.addEventListener("load", () => initializeIFrame(iframe));
	window.addEventListener("resize", () => resizeIFrame(iframe));
};

const initializeIFrame = iframe => {
	if (iframe.dataset.minHeight && iframe.dataset.minHeight.length) {
		iframe.style.minHeight = iframe.dataset.minHeight;
	}

	resizeIFrame(iframe);

	$(iframe).closest(`.${className}`).addClass(initializedClassName);

	const mutationObserver = new MutationObserver(() => resizeIFrame(iframe));
	mutationObserver.observe(iframe.contentWindow.document.documentElement, { subtree: true, childList: true });
};

const resizeIFrame = iframe => {
	iframe.style.height = iframe.contentWindow.document.documentElement.getBoundingClientRect().height + "px";
};
