import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { isLastPage } from "../../common/paging";
import { openFilters } from "../../common/filterSlider";
import ResultItem from "./ResultItem.jsx";
import LoadMore from "../../common/components/LoadMore.jsx";
import LoadingSpinner from "../../common/components/LoadingSpinner.jsx";
import SortBar from "../../common/components/SortBar.jsx";
import SearchStatus from "../../common/components/SearchStatus.jsx";
import Pagination from "../../common/components/Pagination.jsx";
import {
  RESULTS_DISPLAY_REFRESH,
  RESULTS_DISPLAY_APPEND
} from "../../common/redux/resultsDisplay";

const SearchResultList = ({
  results,
  resultsDisplayStrategy,
  selectedFacets,
  totalResults,
  loading,
  isFirstLoad,
  sorters,
  nextPage,
  gotoPage,
  dismissFacet,
  changeSort,
  handleClearAll,
  query,
  pagination,
  dictionary,
  showMobileFacets,
  facets
}) => {
  const isLast = isLastPage(totalResults, query.perPage, query.page);
  const shouldShowNoResults = results.length === 0 && !isFirstLoad;
  const shouldShowPager = totalResults > query.perPage;
  const shouldShowStatus = !!selectedFacets.length || !!query.q;

  function showFilters() {
    openFilters(); // DOM ops
    showMobileFacets(); // action creator
  }

  function wrapperClass() {
    const isRefreshing =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_REFRESH;
    const isAppending =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_APPEND;
    return classnames("search-results search-results--consultants", {
      "is-refreshing": isRefreshing,
      "is-appending": isAppending
    });
  }

  function resultsRange() {
    // Upper bound of visible results
    let ceiling = query.page * query.perPage;

    // Lower bound of visible results
    let floor = ceiling - query.perPage + 1;

    // make sure the ceiling does not exceed total results
    if (ceiling > totalResults) {
      ceiling = totalResults;
    }

    // if results are only one page, eschew the range entirely
    if (totalResults <= query.perPage) {
      return `${totalResults}`;
    }

    // if we're using load more, we want to show only
    // the ceiling of results displayed
    if (resultsDisplayStrategy === RESULTS_DISPLAY_APPEND) {
      return `${ceiling}`;
    }

    // otherwise, return the full range
    return `${floor} - ${ceiling}`;
  }

  function renderPagingControl() {
    if (pagination) {
      return (
        <Pagination
          totalResults={totalResults}
          resultsPerPage={query.perPage}
          currentPage={query.page}
          handlePageChange={gotoPage}
          dictionary={dictionary} />
      );
    }

    if (!pagination && !isLast) {
      return (
        <LoadMore
          handleClick={nextPage}
          dictionary={dictionary}
          loading={loading}
        />
      );
    }
  }

  function renderFilterToggle() {
    return (
      <button
        type="button"
        className="search-results__filter-open btn--primary"
        onClick={() => showFilters()}
      >
        Filter Your Results
        <svg className="icon">
          <use xlinkHref="/images/svg-sprite.svg#equalizer" />
        </svg>
      </button>
    );
  }

  return (
    <section className={wrapperClass()}>
      {!shouldShowNoResults && shouldShowStatus &&
        (<div className="search-results__status-wrapper">
          <SearchStatus
            resultsRange={resultsRange()}
            totalResults={totalResults}
            keywords={query.q}
            selectedFacets={selectedFacets}
            handleFacetDismiss={dismissFacet}
            handleClearAll={handleClearAll}
            dictionary={dictionary}
            facets={facets}
          />
        </div>)
      }
      <div className="search-results__item-wrapper">
        {loading && (
          <div className="search-results__loader">
            <LoadingSpinner />
          </div>
        )}
        <div className="search-results__items">
          {shouldShowNoResults ? (
                <section className="search-results__no-results">
                  <h3>{dictionary.noResultsLabel}</h3>
                  <ul>
                    <li>{dictionary.checkSpellingLabel}</li>
                    <li>{dictionary.generalWordsLabel}</li>
                    <li>{dictionary.tryDifferentLabel}</li>
                  </ul>
                </section>
            ) : null}
          {results.map(item => <ResultItem key={item.id} {...item} />)}
        </div>
      </div>
      {!shouldShowNoResults && shouldShowStatus && <SearchStatus
        resultsRange={resultsRange()}
        totalResults={totalResults}
        dictionary={dictionary}
      />}
      {shouldShowPager ? renderPagingControl() : null}
    </section>
  );
};

const {
  arrayOf,
  string,
  object,
  func,
  bool,
  number,
  shape,
  oneOfType
} = PropTypes;

SearchResultList.propTypes = {
  results: arrayOf(
    shape({
      id: oneOfType([number, string])
    })
  ),
  resultsDisplayStrategy: number,
  selectedFacets: arrayOf(object).isRequired,
  totalResults: number.isRequired,
  sorters: arrayOf(
    shape({
      id: oneOfType([number, string]).isRequired,
      label: string.isRequired,
      value: string.isRequired,
      direction: oneOfType([bool, string]),
      isActive: bool.isRequired
    })
  ),
  loading: bool,
  isFirstLoad: bool,
  nextPage: func,
  gotoPage: func,
  changeSort: func,
  showMobileFacets: func,
  dismissFacet: func,
  query: shape({
    q: string,
    sortBy: string,
    page: number,
    perPage: number,
    pageId: string
  }),
  pagination: bool,
  dictionary: object
};

SearchResultList.defaultProps = {
  selectedFacets: []
};

export default SearchResultList;
