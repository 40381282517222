import $ from 'jquery';

export class TruncateText {
    constructor($el, text, count) {
        this.$el = $el;
        this.text = text ? text : this.$el.text();
        this.$maxLength = count ? count : this.$el.attr("data-maxlength");
        
        if (this.text.length > this.$maxLength) {
            let tempString = this.text.substr(0, this.$maxLength);
            tempString = tempString.replace(/\s+\S*$/, "");
            this.$el.html(tempString.concat("..."));
        } else { this.$el.html(this.text); }
    }
}