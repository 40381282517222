module.exports = function (window,document,$,undefined) {
	var debug = false;

    if (debug) { console.log('site-nav.js has successfully loaded!'); }
    
    var $mapLink = $('.js-c-office-info__directions');


    function manipulateMapUrl() {
        $mapLink.each(function() {
            var $directionLink = $(this),
                mapUrl = $directionLink.data('map-url'),
                splitUrl = mapUrl && mapUrl.split('.com');
            
            if (!splitUrl || splitUrl.length < 2) {
                return;
            }

            // If it's an iPhone..
            if( (navigator.platform.indexOf("iPhone") != -1) 
                || (navigator.platform.indexOf("iPod") != -1)
                || (navigator.platform.indexOf("iPad") != -1)) {
                $directionLink.on('click', function(e) {
                    window.open("maps://maps.google.com" + splitUrl[1]);
                    e.preventDefault();
                });
                $directionLink.attr('href', 'maps://maps.google.com' + splitUrl[1]);
            } else {
                $directionLink.on('click', function(e) {
                    window.open("http://maps.google.com" + splitUrl[1]);
                    e.preventDefault();
                });
                $directionLink.attr('href', 'http://maps.google.com' + splitUrl[1]);
            }
        });
    }

    function init() {
		manipulateMapUrl();
	} // functions init()

	return {
		init: init
	};

}(window,document,jQuery);