// Note: object-fit has been refactored to be applied on-demand instead of automatically
// You may invoke it directly in your scripts

import "./es6-polyfill";
import "./popstate-event";
import "./closest";

import svg4everybody from "svg4everybody";
svg4everybody();

import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
