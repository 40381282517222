
module.exports = function (window, document, $, undefined) {
	var debug = false;

	if (debug) { console.log('show-hide.js has successfully loaded!'); }

	var shParentClass = 'js-show-hide--parent',
		shTriggerClass = 'js-show-hide--trigger',
		shTargetClass = 'js-show-hide--target',
		shActiveClass = 'is-on';

	// Bind click event(s)
	function shBindClicks() {
		if (debug) { console.log("shBindClicks has been called."); }

		// Click event for the simplest form of show/hide ever... the class toggle
		var $shParentEl = $('.' + shParentClass);
		$shParentEl.on('click', '.' + shTriggerClass, function (e) {
			e.preventDefault();

			if (debug) { console.log("You have clicked on a show/hide trigger."); }

			$(this).parents('.' + shParentClass).toggleClass(shActiveClass);
		});

		$("body").on("click", function (e) {
			var $targetEl = $(e.target);
			if ($shParentEl.hasClass(shActiveClass) && !$targetEl.hasClass(shParentClass) && !$targetEl.hasClass(shTriggerClass)) {
				$shParentEl.removeClass(shActiveClass);
			}
		});

		// Click event for the search show/hide on mobile/tablet views
		$('.js-site-search__mobile--trigger').on('click', function (e) {
			e.preventDefault();

			var shSearchClass = {
				trigger: "js-site-search__mobile--trigger",
				target: "js-site-search__mobile--target",
				open: 'is-open'
			};

			if (debug) { console.log("You have clicked on the search show/hide trigger"); }

			$('.' + shSearchClass.target).toggleClass(shSearchClass.open);
			$('.' + shSearchClass.trigger).toggleClass(shSearchClass.open);
		});

		// Click event for the mobile sub-navigation menu arrow direction switch
		$('.microsite-menu__item--dropdown').on('click', function (e) {
			$('.microsite-dropdown__arrow').toggleClass('icon-arrow-down icon-arrow-up');
		});

	} // function bindNavClicks



	function init() {
		shBindClicks();
	} // functions init()

	return {
		init: init
	};

}(window, document, jQuery);
