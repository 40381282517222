﻿import $ from "jquery";

export class CookieManagement {
  constructor($el) {
    this.$el = $el;

    const dataset = $el[0].dataset;
    this.cookieName = dataset.cookieName;
    this.cookiePolicyName = dataset.cookiePolicyName;
    this.cookieDomain = dataset.cookieDomain;

    this.bindEvents();
  }

  bindEvents() {
    $("input", this.$el).on("change", () => this.setCookie());
  }

  setCookie() {
    var cookiesArray = $("input:checked", this.$el).map(function (_index, element) {
      return $(element).attr("name");
    }).toArray();

    var expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    var expirationDateString = expirationDate.toUTCString();

    document.cookie = `${this.cookieName}=${JSON.stringify(cookiesArray)};domain=${this.cookieDomain};expires=${expirationDateString};path=/`;
    document.cookie = `${this.cookiePolicyName}=yes;domain=${this.cookieDomain};expires=${expirationDateString};path=/`;
  }
}
