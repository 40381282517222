module.exports = function (window,document,$,undefined) {
	var debug = false;

    if (debug) { console.log('site-nav.js has successfully loaded!'); }

    // variables
    var $mobileRegionDropdownTrigger = $('.js-c-office-selector__trigger'),
        $regionSelectLink = $('.js-c-office-selector__link'),
        $regionOfficeListingPane = $('.js-c-office-selector__region-pane');

    //functions
    function main() {
        $mobileRegionDropdownTrigger.on('click', function() {
            var $component = $(this).closest('.js-c-office-selector');
            toggleDropdown($component);
            return false;
        });

        $(document).on('click', function(e) {
            if($mobileRegionDropdownTrigger !== e.target && !$mobileRegionDropdownTrigger.has(e.target).length){
                closeDropdown();
            }
        });

        $regionSelectLink.on('click', function(e) {
            showRegionPane($(this));
            $(this).closest('li').addClass('is-current').siblings().removeClass('is-current');
            $mobileRegionDropdownTrigger.text($(this).find('.c-office-selector__name').text());
            closeDropdown();
            return false;
        });
    }

    function showRegionPane(regionLink) {
        var $regionPane = regionLink.closest('.js-c-office-selector').find('.js-c-office-selector__region-pane[id=' + regionLink.data('target-id') + ']');
        
        $regionOfficeListingPane.removeClass('is-current');
        $regionPane.addClass('is-current');
    }

    function toggleDropdown(el) {
        if(el.hasClass('has-selector-open')) {
            el.removeClass('has-selector-open');
        } else {
            el.addClass('has-selector-open');
        }
    }

    function closeDropdown() {
        var $component = $mobileRegionDropdownTrigger.closest('.js-c-office-selector');

        if($component.hasClass('has-selector-open')) {
            $component.removeClass('has-selector-open');
        }
    }

    function init() {
		main();
	} // functions init()

	return {
		init: init
	};

}(window,document,jQuery);