import $ from "jquery";
import "./custom-vendor/slick-carousel/slick/slick";

export class ImageSlider {
  constructor($el, conf) {
    this.$el = $el;
    let slickConf = Object.assign(this.slickDefaults(), conf);

    // bind event prior to slick init
    this.bindEvents();
    this.$el.slick(slickConf);
  }

  bindEvents() {
    // bind an event on the arrows to de-focus them when
    // you mouse out, so the focus state doesn't get stuck
    this.$el.on('mouseleave', '.slick-next, .slick-prev', event => {
      event.currentTarget.blur();
    });
  }

  slickDefaults() {
    return {
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: `
        <button type="button" class="slick-prev" tabindex="0">
            Previous
            <div class="icon"><svg><use xlink:href="/images/svg-sprite.svg#chevron-left"></use></svg></div>
        </button>`,
      nextArrow: `
        <button type="button" class="slick-next" tabindex="0">
            Next
            <div class="icon"><svg></sbg><use xlink:href="/images/svg-sprite.svg#chevron-right"></use></svg></div>
         </button>`,
    }
  }
}

