import $ from "jquery";

export class SiteFooter {
  constructor(classSelector) {
    this.$el = $(classSelector);

    if (this.$el.length) {
      this.bindEvents();
    }
  }

  bindEvents() {
    const mutationObserver = new MutationObserver(() => this.checkFooter());
    mutationObserver.observe(document.body, { subtree: true, childList: true });

    $(() => this.checkFooter());
    $(window).on("resize", () => this.checkFooter());
  }

  checkFooter() {
    this.$el.css("position", "");
    this.$el.css("bottom", "");

    if (window.innerHeight > document.body.getBoundingClientRect().height) {
      this.$el.css("position", "fixed");
      this.$el.css("bottom", 0);
    }
  }
}
