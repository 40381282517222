import $ from "jquery";

export function init() {
  initializeSlider();
}

function initializeSlider() {
  $(".practice-detail-consultants").each(function () {
    $(this).slick({
      speed: 300,
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      prevArrow: $(".js-practice-consultants-arrow__prev"),
      nextArrow: $(".js-practice-consultants-arrow__next"),
      responsive: [
        {
          breakpoint: 99999,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            dots: false
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    });
  });
}
