import $ from 'jquery';

export class AnchorLink {
    constructor($el) {
        // console.log($el);
        this.$el = $el;
        this.$items = $el.find(".js-policy__list-item");
        this.bindEvents();
        this.$anckorList = $el.find(".js-policy__list");
    }
    bindEvents() {
        this.$items.on('click', event => this.toggleClass(event));
        this.$el.ready(event => this.updateLinks(event))
        
    }
    // On mobile this function  opens and closes anchor links 
    toggleClass(event) {
        event.preventDefault();
        let link = event.currentTarget;
        let links = $("a.ps-anchor-list__item");
        let windowSize = $(window).width();
        if($(link).hasClass('is-open')){
            $(link).removeClass('is-open');
        }else{
            $(link).addClass('is-open');
        }
        if((windowSize < 655) && $(link).hasClass("is-open")) {
            links.addClass("open-links")
        } else if ((windowSize < 655) && (!$(link).hasClass("is-open"))) {
            links.removeClass("open-links")
        }
    }   
    
    updateLinks() {
        $('a').each(function(k,v) {
        $(".js-mobile").append(`<a href="#${$(this).attr("id")}"class="ps-anchor-list__item">${$(this).html()}</a>`);
        })
    }
    
}

