import "core-js/es/array";
import "./polyfill";
import $ from "jquery";
import "picturefill";
import URI from "urijs";
import { createApp } from "./thread/common/factory";
import {
  defaultFacetedSearchFactory,
  defaultDynamicContentFactory,
} from "./thread/search";
import {
  consultantFacetedSearchFactory,
  consultantDynamicContentFactory,
} from "./thread/consultants";
import { ViewAllExpand } from "./thread/ViewAllExpand";
import { ImageSlider } from "./thread/ImageSlider";
import { SecondaryNav } from "./thread/navigation/SecondaryNav";
import { DynamicBanner } from "./thread/DynamicBanner";
import { TruncateText } from "./thread/TruncateText";
import { NavSearch } from "./NavSearch";
import { CookiePolicy } from "./CookiePolicy";
import { CookieManagement } from "./CookieManagement";
import { SiteFooter } from "./SiteFooter";
import * as callout from "./Callout";
import * as featuredConsultants from "./FeaturedConsultants";
import * as featuredInsights from "./FeaturedInsights";
import * as impactMessaging from "./ImpactMessaging";
import "./MvcIntegrationIFrame";

// Kincentric
import { PrimaryNav } from "./kincentric/PrimaryNav";
import { ContactUs } from "./kincentric/ContactUs";
import { VideoPlayer } from "./kincentric/VideoPlayer";
import { LogoAnimation } from "./kincentric/LogoAnimation";
import { AnchorLink } from "./kincentric/AnchorLink";
import { QuickSearch } from "./kincentric/QuckSearch";

//AnchorLink
const $AnchorLink = $(".js-policy__list");
if ($AnchorLink.length) {
  new AnchorLink($AnchorLink);
}

// PrimaryNav
$(document).ready(function () {
  const $PrimaryNav = $(".js-ps-global-header");
  if ($PrimaryNav.length) {
    let primaryNav = new PrimaryNav($PrimaryNav);
  }
});

// Cookie Policy
const $cookiePolicy = $(".js-cookie-policy");
if ($cookiePolicy.length) {
  new CookiePolicy($cookiePolicy);
}

// Cookie Management
const $cookieManagement = $(".js-cookie-management");
if ($cookieManagement.length) {
  new CookieManagement($cookieManagement);
}

//Contact Form

const $ContactUs = $(".js-ps-contact-us");
if ($ContactUs.length) {
  let contactUs = new ContactUs($ContactUs);
}

//video
const $VideoHero = $(".js-ps-hero-video");
if ($VideoHero.length) {
  let videoPlayer = new VideoPlayer($VideoHero);
}

// Display Animation
const $displayAnimation = $(".js-ps-hero-video");
if ($displayAnimation.length) {
  let logoAnimation = new LogoAnimation($displayAnimation);
}

var siteNav = require("./site-nav.js"),
  showHide = require("./show-hide.js"),
  featuredContent = require("./featured-content.js"),
  catDrop = require("./catdrop.js"),
  getDirections = require("./getDirections.js"),
  officeSelector = require("./officeSelector.js");

// PrimaryNav
const $navSearch = $(".js-navigation");
if ($navSearch.length && !$(".js-search-results").length) {
  let navSearch = new NavSearch($navSearch);
}

const $mobileNavSearch = $(".js-mobile-search-target");
if ($mobileNavSearch.length) {
  const uri = new URI(window.location.href);
  if (uri.hasQuery("q", true)) {
    $mobileNavSearch.find("input").val(uri.search(true).q);
  }
  let mobileNavSearch = new NavSearch($mobileNavSearch);
}

// Expandable Listings
const viewAllables = $.makeArray($(".js-view-all-expand")).map(el => {
  return new ViewAllExpand($(el));
});

//Truncate Text
const $textToTruncate = $(".js-truncate");
if ($textToTruncate.length) {
  const truncatedText = $.makeArray($textToTruncate).map(el => {
    return new TruncateText($(el));
  });
}

// Slideshows

// do not intialize slideshows inside
// page editor
if (!$("body").hasClass("is-page-editor")) {
  const slideshows = $.makeArray($(".slideshow .js-slider")).map(el => {
    return new ImageSlider($(el), {});
  });
}

// Secondary Nav
if ($(".js-secondary-nav").length) {
  const secondaryNav = new SecondaryNav($(".js-secondary-nav"));
}

// Dynamic Banners (Hero and Page Banner)
const $banners = $(".js-dynamic-banner");
if ("objectFit" in document.documentElement.style === false) {
  if ($banners.length) {
    const dynamicBanners = $.makeArray($banners).map(el => {
      return new DynamicBanner($(el));
    });
  }
}

// Search Apps
// only init if results are included
if ($(".js-search-results").length) {
  $navSearch.find(".js-navigation__search .js-navigation__title").attr("disabled", "true");
  const searchId = $(".js-search-results").data("searchId");
  const searchApp = createApp(defaultFacetedSearchFactory(searchId));
  searchApp();
}

// only init if results are included
if ($(".js-consultant-results").length) {
  const searchId = $(".js-consultant-results").data("searchId");
  const searchApp = createApp(consultantFacetedSearchFactory(searchId));
  searchApp();
}

if ($(".js-dynamic-results").length) {
  const searchId = $(".js-dynamic-results").data("searchId");
  const dynamicContentApp = createApp(defaultDynamicContentFactory(searchId));
  dynamicContentApp();
}

if ($(".leaderTopics .slides").length) {
  $.makeArray($(".leaderTopics li img")).map(img => {
    $(img)
      .parent("a")
      .on("click", () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "leadershipTopicClick",
          leadershipTopic: `${$(img).attr("alt")}`,
        });
      });
  });
}

if ($(".promo__media").length) {
  $.makeArray($(".promo__media a")).map(imgLink => {
    const articleTitle = $(imgLink)
      .parent()
      .siblings(".promo__content")
      .find(".promo__title a")
      .html();
    $(imgLink).on("click", () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "featuredInsightClick",
        featuredInsightTitle: `${articleTitle}`,
      });
    });
  });
}

// Copied from inline script file.  Script to handle header search redirect
$(".js-navigation__search-box, .js-mobile-search-target").each(function () {
  var $searchBox = $(this),
    $input = $searchBox.find("input"),
    $button = $searchBox.find("button"),
    searchUrl = $searchBox.data("searchUrl");

  var triggerQuery = function () {
    var inputValue = URI.encode($input.val());
    window.location.href = searchUrl + inputValue;
  };

  $button.on("click", function (e) {
    e.preventDefault();
    triggerQuery();
    return false;
  });

  $input.keydown(function (event) {
    var keypressed = event.keyCode || event.which;
    if (keypressed == 13) {
      triggerQuery();
      return false;
    }
  });
});
// Quick Search
const quickSearchEl = $(".js-quick-search");
if ($(quickSearchEl).length) {
  const triggerEl = quickSearchEl.find(
    ".js-search-toggle[data-target=js-quick-search]",
  );
  const quickSearch = new QuickSearch(quickSearchEl, triggerEl);
}

const mobileSearchEl = $(".js-mobile-quick-search");
if (mobileSearchEl.length) {
  const triggerEl = $(".js-search-toggle[data-target=js-mobile-quick-search]");
  const mobileSearch = new QuickSearch(mobileSearchEl, triggerEl);
}

siteNav.init();
showHide.init();
featuredContent.init();
catDrop.init();
getDirections.init();
officeSelector.init();
new SiteFooter(".site-footer");

$(function () {
  callout.init();
  featuredConsultants.init();
  featuredInsights.init();
  impactMessaging.init();
});

$(".sitewide-banner__close-button").on("click", function (e) {
  $(".sitewide-banner").hide();
});
