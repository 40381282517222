import URI from "urijs";
import $ from "jquery";

export const getGeneralSuggestions = (keyword) => {
    return $.ajax({
        url: `/services/sitesearchsuggestions.ashx?q=${URI.encode(keyword)}&lang=${document.documentElement.lang}`
    });
};

export const getConsultantSuggestions = (keyword, params = {}) => {
    let query = {
        q: keyword,
        lang: document.documentElement.lang
    }
    if (Object.keys(params).length) {
        Object.keys(params).forEach(k => {
            params[k] = params[k].split(",");
        })
    }
    return $.ajax({
        url: "/services/consultantsuggestions.ashx",
        data: Object.assign(params, query)
    });
}