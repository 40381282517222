import $ from "jquery";

export function init() {
  initializeSlider();
  $(window).on("resize", initializeSlider);
}

var sliderIsActive = false;

function initializeSlider() {
  if (window.innerWidth >= 960) {
    sliderIsActive = false;
    return;
  }

  if (sliderIsActive) {
    return;
  }

  $(".practice-detail-callout__copy").each(function () {
    var callout = $(this).closest(".js-callout-wrapper");
    var prevArrow = $(callout).find(".js-practice-callout-arrow__prev");
    var nextArrow = $(callout).find(".js-practice-callout-arrow__next");

    $(this).slick({
      prevArrow: prevArrow,
      nextArrow: nextArrow,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 960,
          settings: "unslick"
        }
      ]
    });
  });

  sliderIsActive = true;
}
