import $ from "jquery";
import { VideoPlayer } from "./VideoPlayer";

export class LogoAnimation {
  constructor($el) {
    this.$el = $el;
    this.$logo = $(".js-ps-video__animation-logo");
    this.$tagline = $(".js-ps-video__animation-tagline");
    this.$fadeIn = $(".js-ps-hero__fadein");
    this.$fullLogo = $(".js-ps-video__animation");
    this.$overlay = $(".js-ps-hero-video__animation-overlay");
  }
  displayVideoPlayer() {
    const $VideoHero = this.$el;
    if ($VideoHero.length) {
      new VideoPlayer($VideoHero);
    }
  }

  bindEvents() {
    this.checkCookie();
  }

  checkCookie() {
    console.log(this);
    const that = this;

    if (
      document.cookie
        .split(";")
        .filter(item => item.trim().startsWith("psAnimationSeen=")).length
    ) {
      this.$fadeIn.css("opacity", 1);
      this.$fadeIn.attr("aria-hidden", "false");
      this.$fullLogo.css("display", "none");
      this.$overlay.css("display", "none");
      that.displayVideoPlayer();
    }
    else {
      that.displayAnimation();
    }
  }


  displayAnimation() {
    const that = this;
    let expiration = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    document.cookie = "psAnimationSeen=true; expires=" + expiration;
    this.$logo.delay(300).fadeIn();
    this.$tagline.delay(900).fadeIn();
    this.$fullLogo.delay(2600).fadeOut();
    this.$overlay.delay(3600).fadeOut();
    this.$fadeIn.each(function (i) {
      $(this)
        .delay(4000 + i * 200)
        .queue(function (next) {
          $(this).css({
            opacity: "1",
            "z-index": "102",
            transition: "opacity ease-in 200ms"
          });
          next();
        });
    });
    this.$fadeIn.attr("aria-hidden", "false");
    this.$fullLogo.delay(2601).queue(function (next) {
      $(this).css("display", "none"); //display none to make play button clickable
      // this.$overlay.css('display', 'none');
      //video
      that.displayVideoPlayer();
    });
  }

  init() {
    this.bindEvents();
  }
}
