import PropTypes from 'prop-types';
import React from "react";
import $ from "jquery";
import { isLastPage, isFirstPage, totalPages, pageBlock } from "../paging";

const Pagination = ({
  totalResults,
  resultsPerPage,
  currentPage,
  handlePageChange,
  dictionary
}) => {

  const block = pageBlock(totalResults, resultsPerPage, currentPage);
  const isLast = isLastPage(totalResults, resultsPerPage, currentPage);
  const isFirst = isFirstPage(currentPage);
  const lastPage = totalPages(totalResults, resultsPerPage);

  function getPage(label, value, isDisabled, isActive = false) {
    const isPageBtn = (label !== "prev") && (label !== "next") && (label !== "first") && (label !== "last");
    return (
      <button
        className={`btn btn--link ${isActive ? "is-active" : ""} ${!isPageBtn ? "nav-btn" : null}`}
        key={label}
        type="button"
        onClick={() => onPageClick(value, isDisabled)}
        disabled={isDisabled}>
          {isPageBtn && label }
          {label === "first" ? 
            <div className="fake-double-chevron">
              <span className="u-visuallyhidden">{dictionary.firstPageLabel}</span>
              <svg className="icon">
                <use xlinkHref="/images/svg-sprite.svg#chevron-left"></use>
              </svg>
              <svg className="icon icon--double-chevy">
                <use xlinkHref="/images/svg-sprite.svg#chevron-left"></use>
              </svg>
            </div> : null }
          {label === "last" ? 
            <div className="fake-double-chevron">
              <span className="u-visuallyhidden">{dictionary.lastPageLabel}</span>
              <svg className="icon">
                <use xlinkHref="/images/svg-sprite.svg#chevron-right"></use>
              </svg>
              <svg className="icon icon--double-chevy">
                <use xlinkHref="/images/svg-sprite.svg#chevron-right"></use>
              </svg>
            </div> : null }
          {label === "prev" ? 
            <svg className="icon">
              <title>{dictionary.previousPageLabel}</title>
              <use xlinkHref="/images/svg-sprite.svg#chevron-left"></use>
            </svg> : null }
          {label === "next" ? 
            <svg className="icon">
              <title>{dictionary.nextPageLabel}</title>
              <use xlinkHref="/images/svg-sprite.svg#chevron-right"></use>
            </svg> : null }
        </button>
    );
  }

  function onPageClick(pageNum, isDisabled) {
    if (!isDisabled) {
      const searchResults = $(".search-results");
      if (searchResults) {
        window.scrollTo({
          top: searchResults.offset().top,
          behavior: "smooth"
        });
      }
      handlePageChange(pageNum);
    }
  }

  return (
    <div className="search-results__pagination">
      <div className="search-results__pages">
        {getPage("first", 1, isFirst)}
        {getPage("prev", currentPage - 1, isFirst)}
        {block.map( page => getPage(page.label, page.num, page.current, page.current))}
        {getPage("next", currentPage + 1, isLast)}
        {getPage("last", lastPage, isLast)}
      </div>
    </div>
  );
};

const { number, func } = PropTypes;

Pagination.propTypes = {
  totalResults: number.isRequired,
  resultsPerPage: number.isRequired,
  currentPage: number.isRequired,
  handlePageChange: func.isRequired
};

export default Pagination;
