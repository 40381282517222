import PropTypes from 'prop-types';
import React from "react";
import R from "ramda";

const SearchStatus = ({
  resultsRange,
  totalResults,
  keywords,
  selectedFacets,
  handleFacetDismiss,
  handleClearAll,
  dictionary,
  facets
}) => {

  const shouldRenderFacets = selectedFacets.length > 0;
  const shouldRenderKeywords = !!keywords;

  const onClearAll = (event) => {
    event.preventDefault();
    handleClearAll();
  };

  const onFacetClick = (value) => {
    const group = R.find(R.propEq("id", value.parent), facets);
    handleFacetDismiss(value.id, group);
  };

  const resultLabel = totalResults === 1 ? "result" : "results";

  const renderSelectedFacets = () => {
    return (
      <div className="search-results__active-facets">
        <span>{dictionary.selectedTagsLabel}</span>&nbsp;
        <ul>
          <li className="label">{dictionary.selectedTagsLabel}</li>
          {selectedFacets.map(value => {
            return (
              <li key={`${value.id}_${value.parent}`}>
                <span>{value.name}</span>
                <button
                  onClick={() => onFacetClick(value)}
                  aria-label={`${dictionary.clearLabel} ${value.name} ${dictionary.filterLabel}`}>

                  <svg className="icon">
                    <use xlinkHref="/images/svg-sprite.svg#close"></use>

                  </svg>
                </button>
              </li>
            );
          })}
          <button className="clear-btn" onClick={(e) => onClearAll(e)}>{dictionary.clearAllLabel}</button>
        </ul>
      </div>
    );
  };

  return (
    <div className="search-results__status">
      {shouldRenderFacets && renderSelectedFacets()}
      <span><b>{resultsRange}</b></span>&nbsp;
      <span>{dictionary.ofLabel}</span>&nbsp;
      <span><b>{totalResults}</b></span>&nbsp;
      <span>{resultLabel}</span>&nbsp;
      { shouldRenderKeywords && (
        <div className="search-results__status__keywords-container">
          {dictionary.forLabel} &quot;<span className="search-results__status__keywords">{unescape(keywords)}</span>&quot;&nbsp;
        </div>
      )}
    </div>
  );

};

const { string, number, object, arrayOf, func } = PropTypes;

SearchStatus.propTypes = {
  resultsRange: string.isRequired,
  totalResults: number.isRequired,
  keywords: string,
  selectedFacets: arrayOf(object),
  handleFacetDismiss: func,
  handleClearAll: func
};

SearchStatus.defaultProps = {
  selectedFacets: []
};

export default SearchStatus;
