import PropTypes from 'prop-types';
import React from "react";
import $ from "jquery";
import LoadingSpinner from "./LoadingSpinner.jsx";
import TypeFilter from './TypeFilter.jsx';
import { allSelectedFacets } from '../redux/facets.js';

export default class SearchBoxGray extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initiatedLoad: false,
      currentInput: this.props.keywords || "",
      openMenu: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading) {
      this.setState({ initiatedLoad: false });
    }
    if (nextProps.keywords !== this.state.currentInput) {
      this.setState({ currentInput: nextProps.keywords });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (allSelectedFacets(this.props.facets).length || this.keywordInput.value || !this.props.isGeneralSearch) {
      if ($(".js-navigation__search")) {
        $(".js-navigation__search").find(".js-search-input").val(this.keywordInput.value);
      }
      if ($(".js-mobile-search-target")) {
        $(".js-mobile-search-target").find("input").val(this.keywordInput.value);
      }
      this.props.handleSpecificItem(null, this.keywordInput.value);
      this.setState({ initiatedLoad: true });
    } else { }
  }

  handleReset(event) {
    event.preventDefault();
    this.keywordInput.value = "";
    if (allSelectedFacets(this.props.facets).length || !this.props.isGeneralSearch) {
      this.props.handleSpecificItem(null, this.keywordInput.value);
    } else { this.setState({ currentInput: this.keywordInput.value }) }
  }

  handleInputChange(event) {
    this.setState({ currentInput: this.keywordInput.value });
  }

  handleBlur(event, typeFilterIsBlurred = false) {
    const focusedEl = event.relatedTarget || document.activeElement; // becacuse IE11 doesn't use relatedTarget
    const searchHasFocus = !!$(this.form).find(focusedEl).length && !(focusedEl === null);
    if (!this.props.isTypeFilter || typeFilterIsBlurred || !searchHasFocus) {
      this.setState({ openMenu: false });
    }
  }

  handleFocus() {
    this.setState({ openMenu: true })
  }

  render() {
    const { dictionary, keywords, loading, isTypeFilter, isGray } = this.props;
    const { initiatedLoad, currentInput, openMenu } = this.state;
    const keywordIsCurrent = currentInput === keywords;
    const shouldShowClear = keywordIsCurrent && currentInput;
    const shouldShowSpinner = initiatedLoad && loading;
    const shouldShowTypeFilter = isTypeFilter && currentInput && !keywordIsCurrent && openMenu;
    return (
      <form className={`search-box ${isGray ? "search-box--gray" : ""} ${shouldShowClear ? "search-box--clear" : ""}`} onBlur={(event) => this.handleBlur(event)} ref={form => this.form = form}
        onFocus={() => this.handleFocus()} onSubmit={e => this.handleSubmit(e)} onReset={e => this.handleReset(e)} noValidate>
        <div className="search-box__input">
          <div className="search-box__input-area">
            <label htmlFor="site-search" className="u-visuallyhidden">{dictionary.searchBoxLabel}</label>
            <input id="site-search"
              type="text"
              placeholder={dictionary.searchBoxPlaceholder}
              autoComplete={isTypeFilter ? "off" : "on"}
              onChange={e => this.handleInputChange(e)}
              ref={input => this.keywordInput = input}
              value={currentInput && unescape(currentInput) || ""}
            />
            {shouldShowClear && <button type="reset" className="search-box__clear">
              {shouldShowSpinner ? null :
                <svg className="icon" role="img" aria-label={dictionary.clearSearchLabel}>
                  <title>{dictionary.clearSearchLabel}</title>
                  <use xlinkHref="/images/svg-sprite.svg#close"></use>
                </svg>
              }
            </button>
            }
            <div className="search-box__loader">
              {shouldShowSpinner ? <LoadingSpinner /> : null}
            </div>
          </div>
          {!shouldShowClear && isGray && <button type="submit" className="search-box__button">
            <svg className="icon" role="img" aria-label={dictionary.runSearchLabel}>
              <title>{dictionary.runSearchLabel}</title>
              <use xlinkHref="/images/svg-sprite.svg#search"></use>
            </svg>
          </button>}
          {!isGray &&
            <button type="submit" className="search-box__button">
              <span className="lg-screen-only">{dictionary.searchBoxLabel}</span>

              <svg className="icon mobile-only" role="img" aria-label={dictionary.runSearchLabel}>
                <title>{dictionary.runSearchLabel}</title>
                <use xlinkHref="/images/svg-sprite.svg#search"></use>
              </svg>
            </button>}
        </div>
        {shouldShowTypeFilter ?
          <TypeFilter
            handleSpecificItem={(id, value) => this.props.handleSpecificItem(id, value)}
            handleFacetChange={(id, value) => this.props.handleFacetChange(id, value)}
            handleBlur={(event, blur) => this.handleBlur(event, blur)}
            handleFocus={() => this.handleFocus()}
            facets={this.props.facets}
            dictionary={dictionary}
            isGeneralSearch={this.props.isGeneralSearch}
            keywordInput={currentInput} /> : null}
      </form>
    );
  }
}

const { string, func, shape, bool } = PropTypes;

SearchBoxGray.propTypes = {
  keywords: string,
  handleEntry: func.isRequired,
  handlekeywordChange: func,
  loading: bool,
  isTypeFilter: bool,
  dictionary: shape({
    searchBoxPlaceholder: string,
    searchBoxLabel: string
  }).isRequired
};
