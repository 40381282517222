import R from "ramda";
import * as RA from "ramda-adjunct";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

export default class ResultItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemWrapper = null;
  }

  componentDidMount() {
    this.itemWrapper.className = this.itemWrapper.className + " is-visible";
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    return moment(date).format("MMMM Do, YYYY");
  }

  renderImage() {
    const { image1x1, contentUrl, title, body } = this.props;
    if (image1x1) {
      return (
        <div className="consultant-card__image-container">
          <figure className="consultant-card__image consultant-card__image--circle">
              <img src={image1x1} alt={title}/>
            <figcaption className="u-visuallyhidden">{title}</figcaption>
          </figure>
        </div>
      );
    } else {
      return <div className="list-item__media-shim">&nbsp;</div>;
    }
  }

  renderEyebrow() {
    const { contentType, date, location } = this.props;
    const itemPairs = R.compose(
      R.splitEvery(2),
      R.intersperse("|"),
      RA.compact
    )([contentType, date, location]);

    if (!itemPairs.length) {
      return;
    }

    const items = RA.mapIndexed(
      ([val, sep], idx) => (
        <React.Fragment key={idx}>
          <span className="list-item__eyebrow-item">{val}</span>
          {sep && <span className="list-item__eyebrow-separator">{sep}</span>}
        </React.Fragment>
      ),
      itemPairs
    );

    return <h2 className="list-item__eyebrow">{items}</h2>;
  }

  render() {
    const {
      title,
      body,
      contentType,
      offices,
      displayUrl,
      contentUrl,
      location
    } = this.props;

    const hasOffices = offices ? offices.length > 0 : false;

    return (
      <article className="consultant-card" ref={el => (this.itemWrapper = el)}>
        <a href={contentUrl}>
          <div className="consultant-card__container">
            {this.renderImage()}
            <div className="consultant-card__content">
              <h3 className="consultant-card__title">{title}</h3>
              { hasOffices && (
                <ul className="consultant-card__offices">
                  {offices.map((office, key) => <li key={key}>{office}</li>)}
                </ul>
              )}
            </div>
          </div>
        </a>
      </article>
    );
  }
}

const { arrayOf, string } = PropTypes;
ResultItem.propTypes = {
  offices: arrayOf(string),
  title: string.isRequired,
  body: string,
  contentUrl: string.isRequired,
  displayUrl: string,
  contentType: string,
  location: string,
  image1x1: string
};
