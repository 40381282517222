import $ from "jquery";
import "core-js/features/object";
import Youtube from "youtube-player";
export class VideoPlayer {
  //TODO: hook up inline video
  constructor($el) {
    this.$el = $el;
    this.$playButton = $(".js-ps-video__play-button");
    this.player = {};
    this.$videoMountPoint = $el.find(".js-ps-video__mount");
    this.videoId = this.$videoMountPoint.attr("data-videoid");
    this.$modal = $(".js-ps-video__modal");
    this.$modalContent = $(".ps-video____box");
    this.$inlineVideo = $("video.js-banner-media");
    this.isiOS = navigator.userAgent.match(/(iPhone|iPod|iPad)/i);
    this.isIe11 = !!window.MSInputMethodContext && !!document.documentMode;

    this.initialize();
    this.bindEvents();
  }
  bindEvents() {
    this.$playButton.click(e => {
      this.playVideo(e);
    });

    if (this.$modal.length) {
      this.$modal
        .find(".js-ps-modal__close__btn")
        .click(e => this.stopVideo(e));
    }
    $(".ps-modal__overlay").click(e => this.stopVideo(e));
  }

  initialize() {
    if (!this.isIe11) {
      this.player = Youtube(this.videoId, {
        videoId: this.videoId,
        playerVars: {
          rel: 0,
          height: "100%",
          width: "100%"
        }
      });
      this.player.on("stateChange", event => this.handleYoutubeState(event));
    }
  }

  playCSS() {
    this.$modal.addClass("is-open");
    this.$el.css("visibility", "visible");
    if (this.isIe11) {
      this.$videoMountPoint.append(
        `<iframe width="560" height="315" src="//www.youtube.com/embed/${this.videoId}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
      );
    }
  }

  stopCSS() {
    this.$modal.removeClass("is-open");
    if (this.isIe11) {
      this.$videoMountPoint.find("iframe").remove();
    }
  }

  handleYoutubeState(event) {
    /*
      State codes are:
      -1 (unstarted)
      0 (ended)
      1 (playing)
      2 (paused)
      3 (buffering)
      5 (video cued).
    */
    // if (event.data === 0) {
    //   if (this.$modal.length) {
    //     this.stopCSS();
    //   }
    // }
  }

  playVideo() {
    if (!this.isIe11) {
      this.player.playVideo();
    }
    if (this.$inlineVideo.length && !this.isiOS) {
      this.$inlineVideo.each(function(index) {
        $(this)[0].pause();
      });
    }

    if (this.$modal.length) {
      window.setTimeout(() => this.playCSS(), 395);
    }
    return false;
  }

  stopVideo() {
    if (!this.isIe11) {
      this.player.stopVideo();
    }
    if (this.$inlineVideo.length && !this.isiOS) {
      this.$inlineVideo.each(function(index) {
        $(this)[0].play();
      });
    }

    if (this.$modal.length) {
      this.stopCSS();
    }

    return false;
  }
}
